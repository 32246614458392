import React, { useRef } from 'react'
import styled from '@emotion/styled'

import { ReactComponent as Magnifier } from '@/assets/svg/search.svg'

interface Props {
  setSearchQuery: (value: string) => void
  placeholder?: string
}

function CabinetSearchInput({ setSearchQuery, placeholder }: Props) {
  const inputRef = useRef<HTMLInputElement>(null)

  function onChangeInput(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchQuery(event.target.value)
  }

  return (
    <Field>
      <SearchIcon>
        <Magnifier />
      </SearchIcon>
      <SearchInput
        ref={inputRef}
        type='text'
        name='search'
        placeholder={placeholder ?? ''}
        onChange={event => onChangeInput(event)}
      />
    </Field>
  )
}

const Field = styled.div`
  position: relative;
  width: 100%;
`

const SearchIcon = styled.div`
  position: absolute;
  content: '';
  top: 50%;
  left: 10px;
  width: 20px;
  height: 20px;
  filter: invert(38%) sepia(14%) saturate(21%) hue-rotate(178deg)
    brightness(94%) contrast(87%);
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 10;

  svg {
    width: 100%;
    height: 100%;
  }
`

const SearchInput = styled.input`
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 10px 15px 10px 45px;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.blue100};
  background-color: ${props => props.theme.white};
  background-clip: padding-box;
  border: none;
  appearance: none;
  border-radius: 0;
  box-shadow: 0 0 0 1px ${props => props.theme.gray400} inset;
  transition:
    0.15s,
    height 0s,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &::placeholder {
    color: #6c757d;
    opacity: 1;
  }

  &:hover {
    box-shadow: 0 0 0 1px ${props => props.theme.gray400} inset;
    background: rgba(0, 97, 237, 0.03);
  }

  &:disabled,
  &[readonly] {
    background-color: #e9ecef;
    opacity: 1;
  }

  &:not([readonly]):focus {
    outline: 0;
    background: ${props => props.theme.white};
    box-shadow: 0 0 0 1px ${props => props.theme.main} inset;
  }
`

export default CabinetSearchInput
