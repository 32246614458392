import React, { useState } from 'react'
import moment from 'moment/moment'

import InformationCard from '../InformationCard'
import UnorderedLists from '../UnorderedLists'
import UnorderedListsItem from '../UnorderedLists/components/UnorderedListsItem'
import ChangeField from '../ChangeField'

import { UserProfileModel } from '@/services/users/typings'
import PersonalDataFormContainer from '@/components/PersonalCabinetForms/PersonalDataForm'
import Spinner from '@/components/Spinner'
import { FormOverlay } from '@/components/PersonalCabinetForms/components/CabinetForm.style'

interface Props {
  profileInformation: UserProfileModel
}

function PersonalData({ profileInformation }: Props) {
  const { name, patronymic, lastName, birthdate, gender } = profileInformation
  const [formVisible, setFormVisible] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)

  return (
    <InformationCard
      title={'Личные данные'}
      percentList={[name, patronymic, lastName, birthdate, gender]}
      description={
        'Личные данные помогут персонализировать специальные предложения'
      }
    >
      {!formVisible ? (
        <>
          <UnorderedLists>
            <UnorderedListsItem label={'Ваше имя'} text={name} />
            <UnorderedListsItem label={'Ваше отчество'} text={patronymic} />
            <UnorderedListsItem label={'Ваша фамилия'} text={lastName} />
            <UnorderedListsItem
              label={'Дата рождения'}
              text={moment(birthdate, 'YYYY-MM-DD').format('DD.MM.YYYY')}
            />
            <UnorderedListsItem
              label={'Пол'}
              text={gender ? (gender === 'FEMALE' ? 'Женский' : 'Мужской') : ''}
            />
          </UnorderedLists>
          <ChangeField
            state={formVisible}
            changeState={(value: boolean) => setFormVisible(value)}
          />
        </>
      ) : (
        <>
          <PersonalDataFormContainer
            setFormVisible={(value: boolean) => setFormVisible(value)}
            setLoading={(value: boolean) => setLoading(value)}
            formDefaultValue={{
              firstName: name,
              patronymic,
              lastName,
              birthdate,
              gender,
            }}
          />
          <FormOverlay isLoading={isLoading}>
            <Spinner absolute={true} isCabinet={true} />
          </FormOverlay>
        </>
      )}
    </InformationCard>
  )
}

export default PersonalData
