import React from 'react'
import Tippy from '@tippyjs/react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import { InfoLinkProps } from './types'

import { ReactComponent as InfoIcon } from '@/assets/svg/info.svg'
import { isBrowser } from '@/utils/common'

function InfoLink({ label, link, info, isNewTab, onClick }: InfoLinkProps) {
  return (
    <Component>
      <LinkItem
        to={link}
        target={isNewTab ? '_blank' : '_self'}
        onClick={() => onClick?.(link)}
      >
        {label}
      </LinkItem>

      <Tippy
        content={<Info>{info}</Info>}
        placement='bottom'
        interactive
        appendTo={isBrowser() ? document.body : 'parent'}
      >
        <StyledInfoIcon />
      </Tippy>
    </Component>
  )
}

export default InfoLink

const Component = styled.div`
  display: flex;
`

const LinkItem = styled(Link)`
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid transparent;
  color: ${props => props.theme.main};

  &:hover {
    border-color: ${props => props.theme.main};
  }
`

const Info = styled.div`
  max-width: 280px;
  padding: 15px;
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  border: 0.5px solid ${props => props.theme.gray400};
  box-sizing: border-box;
  box-shadow: ${props => props.theme.blackShadow100};
  border-radius: 8px;
  background: ${props => props.theme.white};
  color: ${props => props.theme.black};
`

const StyledInfoIcon = styled(InfoIcon)`
  flex: 0 0 13px;
  width: 13px;
  height: 13px;
  margin-left: 5px;

  path:first-child {
    stroke: ${props => props.theme.gray1500};
  }

  path:not(:first-child) {
    fill: ${props => props.theme.gray1500};
  }
`
