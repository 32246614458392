import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { useLocationData } from '@/components/Layout/components/Header/components/Location/hooks'
import { locationData } from '@/components/Layout/components/Header/components/Location/constants'
import { ReactComponent as NavigationIcon } from '@/assets/svg/navigation.svg'
import { ReactComponent as ArrowIcon } from '@/assets/svg/chevron-down.svg'

function LocationDesktop() {
  const {
    isOpen,
    getMenuProps,
    getToggleButtonProps,
    getItemProps,
    selectedItem,
  } = useLocationData()
  return (
    <Component>
      <Select type='button' {...getToggleButtonProps()}>
        <StyledNavigationIcon />
        <Label>{selectedItem?.label}</Label>
        <StyledArrowIcon isOpen={isOpen} />
      </Select>

      <Content {...getMenuProps()} isOpen={isOpen}>
        {locationData.map((item, index) => {
          const isActive = item.value === selectedItem?.value

          return (
            <ListItem
              key={index}
              isActive={isActive}
              {...getItemProps({
                item: item,
                index,
              })}
            >
              {item.label}
            </ListItem>
          )
        })}
      </Content>
    </Component>
  )
}

export default LocationDesktop

const Component = styled.div`
  position: relative;
  width: 90px;
`

const Select = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 16px;
`

const StyledNavigationIcon = styled(NavigationIcon)`
  flex: 0 0 10px;
  width: 10px;
  height: 10px;
`

const Label = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-left: 5px;
  color: ${props => props.theme.white};
`

const StyledArrowIcon = styled(ArrowIcon)<{ isOpen: boolean }>`
  flex: 0 0 10px;
  width: 10px;
  height: 10px;
  margin-left: 5px;
  transition: transform 0.15s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`

const Content = styled.div<{ isOpen: boolean }>`
  z-index: 5;
  pointer-events: none;
  position: absolute;
  top: 25px;
  width: 100%;
  max-height: 0;
  background: ${props => props.theme.white};
  box-shadow: ${props => props.theme.blackShadow100};
  opacity: 0;
  transition: all 0.3s ease-in-out;

  ${({ isOpen }) =>
    isOpen &&
    css`
      pointer-events: auto;
      opacity: 1;
      max-height: 200px;
      transition: all 0.3s ease-in-out;
    `}
`

const ListItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 7px 10px;
  transition: all 0.3s ease-in-out;
  color: ${props => props.theme.black200};

  &:hover {
    color: ${props => props.theme.main};
    background: ${props => props.theme.white_1};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      display: none;
    `}
`
