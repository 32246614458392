import React from 'react'
import styled from '@emotion/styled'

interface Props {
  text: string
}

function CabinetError({ text }: Props) {
  return <Component>{text}</Component>
}

export default CabinetError

const Component = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  padding: 0 20px;
`
