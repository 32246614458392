import React from 'react'
import styled from '@emotion/styled/macro'

import { CarsDropdownProps } from './types'

import { bodyIconMap } from '@/constants/icons'
import { HeaderVariables } from '@/components/Layout/components/Header/components/Menu/constants'
import Dropdown from '@/components/Layout/components/Header/components/Dropdown'
import MenuColumn from '@/components/Layout/components/Header/components/MenuColumn'

function CarsDropdown({ menuItem }: CarsDropdownProps) {
  return (
    <Dropdown
      label={menuItem.label.replace(HeaderVariables.Cars, '').trim()}
      link={menuItem.link ?? ''}
      isNewTab={menuItem.isNewTab}
    >
      <Inner>
        {menuItem.children.map((item, index) => (
          <MenuColumn
            key={index}
            title={item.label}
            menu={item.children.map(item => ({
              title: item.label,
              link: item.link ?? '',
              isNewTab: item.isNewTab,
              icon: bodyIconMap[item.label],
            }))}
            columns={index + 1 !== menuItem.children.length ? 2 : 1}
          />
        ))}
      </Inner>
    </Dropdown>
  )
}

export default CarsDropdown

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
`
