import masterCard from '@/assets/svg/payments/mastercard.svg'
import masterCard2 from '@/assets/svg/payments/mastercard2.svg'
import visa from '@/assets/svg/payments/visa.svg'
import mtbank from '@/assets/svg/payments/mtbank.svg'
import visa2 from '@/assets/svg/payments/visa2.svg'
import webpay from '@/assets/svg/payments/webpay.svg'
import belcard from '@/assets/svg/payments/belcard.svg'
import belcard2 from '@/assets/svg/payments/belcard2.svg'

export const payments: string[] = [
  masterCard,
  masterCard2,
  visa,
  mtbank,
  visa2,
  webpay,
  belcard,
  belcard2,
]
