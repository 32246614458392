import * as t from 'typed-contracts'

import RequestError from '@/services/api/RequestError'
import { Nullable } from '@/typings/common'
import { UserProfileModel } from '@/services/users/typings'

export function isBrowser(): boolean {
  return typeof window !== 'undefined'
}

export function isServer(): boolean {
  return !isBrowser()
}

export function canUseDOM(): boolean {
  return (
    typeof window !== 'undefined' &&
    typeof window.document !== 'undefined' &&
    typeof window.document.createElement !== 'undefined'
  )
}

export function generateNumberArray(length: number): Array<number> {
  return Array.from({ length }, (_, index) => index)
}

export function isNotNullish<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined
}

export function isObjectGuard(
  value: any,
): value is { [key: string]: unknown } | null {
  return typeof value === 'object'
}

export function isNonNullObjectGuard(
  value: any,
): value is { [key: string]: unknown } {
  return isObjectGuard(value) && Boolean(value)
}

const requestErrorBodyContract = t.object({
  errors: t.objectOf(
    t.object({
      code: t.string,
      message: t.string,
    }),
  ),
})

const requestErrorBodySimpleContract = t.object({
  errors: t.objectOf(t.string),
})

export function convertRequestErrorToMap(error: Error): Record<string, string> {
  if (error instanceof RequestError) {
    if (!error.body) {
      return {}
    }

    const validationResult = requestErrorBodyContract(
      'RequestError.body',
      error.body,
    )

    if (!(validationResult instanceof t.ValidationError)) {
      return Object.keys(validationResult.errors).reduce<
        Record<string, string>
      >((result, key) => {
        result[key] = validationResult.errors[key]?.message || ''
        return result
      }, {})
    }

    const validationSimpleResult = requestErrorBodySimpleContract(
      'RequestError.body',
      error.body,
    )

    if (!(validationSimpleResult instanceof t.ValidationError)) {
      return Object.keys(validationSimpleResult.errors).reduce<
        Record<string, string>
      >((result, key) => {
        result[key] = validationSimpleResult.errors[key] || ''
        return result
      }, {})
    }

    return {}
  }

  return {}
}

export const getProfilePercentFullness = (
  profileInformation: Nullable<UserProfileModel>,
) => {
  if (!profileInformation) {
    return 0
  }

  const {
    socialGoogle,

    socialYandex,
    socialVkontakte,
    name,
    patronymic,
    lastName,
    birthdate,
    gender,
    emails,
    phones,
  } = profileInformation

  const dataArray = [
    socialGoogle,
    // socialFacebook,
    socialYandex,
    socialVkontakte,
    name,
    patronymic,
    lastName,
    birthdate,
    gender,
    emails[0],
    phones[0],
    // passport.country,
    // passport.seria,
    // passport.number,
    // passport.personalNumber,
    // passport.company,
    // passport.date,
    // livingAddress.country && livingAddress.city,
    // livingAddress.zip,
    // livingAddress.street,
    // livingAddress.house,
    // livingAddress.korpus,
    // livingAddress.apartment,
    // registrationAddress.country && registrationAddress.city,
    // registrationAddress.zip,
    // registrationAddress.street,
    // registrationAddress.house,
    // registrationAddress.korpus,
    // registrationAddress.apartment,
  ]

  const dataArrayLength = dataArray.length
  const dataArrayNoEmpty = dataArray.filter(Boolean).length

  return Math.ceil((100 / dataArrayLength) * dataArrayNoEmpty)
}
