import {
  AddEmailPayload,
  AddPhonePayload,
  AddPhotoPayload,
  AddressLivingPayload,
  AddSocialInTheProfilePayload,
  CardDetailsType,
  CarsItemHistoryType,
  CarsItemRecommendationsType,
  CarsItemServiceCompaniesType,
  CarsItemTOType,
  CarsItemType,
  DeleteEmailPayload,
  DeletePhonePayload,
  IBookmarksItemPayload,
  IUserRequestsType,
  LoginFormPayload,
  PassportDetailsPayload,
  PersonalDataPayload,
  PurchasesCarsItemType,
  PurchasesInsuranceItemType,
  PurchasesOthersItemType,
  PurchasesServiceItemType,
  ServiceAvailableTimeSlotsPayload,
  CabinetBrandsPayload,
  ServiceCarsPayload,
  ServiceTOPayload,
  ServiceTOPayloadDetails,
  SignInResponse,
  SignInWithSocialPayload,
  SignInWithSocialResponse,
  UserBonusesItemType,
  UserBonusesType,
  UserBookmarksType,
  UserProfileModel,
  UserProfilePreview,
  LoginFormResponse,
  SignInWithSocialPhonePayload,
} from './typings'

import { ApiService } from '@/services/api'
import { Nullable } from '@/typings/common'

export const accessTokenCookieName =
  process.env.REACT_APP_COOKIE_ACCESS_TOKEN_PARAM || 'accessToken'

export const userApiService = new ApiService({
  url: process.env.REACT_APP_USERS_SERVICE_URL ?? '',
  accessTokenCookieName,
  useRefreshToken: false,
  cookieDomain: process.env.REACT_APP_COOKIE_DOMAIN,
})

const request = userApiService.getRequest()

/** Sign In **/

export function signIn(payload: { phone: string }): Promise<SignInResponse> {
  return request.post({ path: '/auth/login', body: payload })
}

export function signInUsingSocialWithPhone(
  payload: SignInWithSocialPhonePayload,
): Promise<{ token: string }> {
  return request.post({ path: '/auth/social/phone', body: payload })
}

export function signInUsingSocial(
  payload: SignInWithSocialPayload,
): Promise<SignInWithSocialResponse> {
  return request.post({ path: '/auth/social', body: payload })
}

export function checkCode(
  payload: LoginFormPayload,
): Promise<LoginFormResponse> {
  return request.post({ path: '/auth/code', body: payload })
}

/** Cabinet Personal Data**/

export function getUserProfilePreview(): Promise<UserProfilePreview> {
  return request.get({
    path: '/profile/preview',
  })
}

export function getUserProfile(): Promise<Nullable<UserProfileModel>> {
  return request.get({
    path: '/profile',
  })
}

export function updatePersonalDataInTheProfile(
  payload: PersonalDataPayload,
): Promise<{
  success: boolean
}> {
  return request.post({
    path: '/profile',
    body: payload,
  })
}

export function updatePassportDetailsInTheProfile(
  payload: PassportDetailsPayload,
): Promise<{
  success: boolean
}> {
  return request.post({
    path: '/profile/passport',
    body: payload,
  })
}

export function updateAddressLivingInTheProfile(
  payload: AddressLivingPayload,
): Promise<{
  success: boolean
}> {
  return request.post({
    path: '/profile/address',
    body: payload,
  })
}

export function addSocialInTheProfile(
  payload: AddSocialInTheProfilePayload,
): Promise<{
  success: boolean
}> {
  return request.post({
    path: '/profile/social',
    body: payload,
    fetchOptions: {},
  })
}

export function addPhone(payload: AddPhonePayload): Promise<{
  success: boolean
  code: string
}> {
  return request.post({
    path: '/profile/phone',
    body: payload,
  })
}

export function addEmail(payload: AddEmailPayload): Promise<{
  success: boolean
  code: string
}> {
  return request.post({
    path: '/profile/email',
    body: payload,
  })
}

export function deletePhone(payload: DeletePhonePayload): Promise<{
  success: boolean
}> {
  return request.delete({
    path: '/profile/phone',
    body: payload,
  })
}

export function deleteEmail(payload: DeleteEmailPayload): Promise<{
  success: boolean
}> {
  return request.delete({
    path: '/profile/email',
    body: payload,
  })
}

export function updatePhoto(
  payload: AddPhotoPayload,
): Promise<{ success: boolean }> {
  return request.post({ path: '/profile/photo', body: payload })
}

/** Cabinet Cars**/

export function getUserCars(): Promise<CarsItemType[]> {
  return request.get({
    path: '/cars',
  })
}

/** Cabinet Bonuses**/

export function getUserBonusesPreview(): Promise<Nullable<UserBonusesType>> {
  return request.get({
    path: '/bonuses/preview',
  })
}

export function getUserBonuses(): Promise<UserBonusesItemType[]> {
  return request.get({
    path: '/bonuses',
  })
}

/** Cabinet Purchases**/

export function getUserPurchasesServices(): Promise<
  PurchasesServiceItemType[]
> {
  return request.get({
    path: '/purchases/service',
  })
}

export function getUserPurchasesServicesDetails(
  dealer: number,
  vin: string,
  document: string,
): Promise<CardDetailsType> {
  return request.get({
    path: `/purchases/service/${document}?vin=${vin}&dealer=${dealer}`,
  })
}

export function getUserPurchasesInsurance(): Promise<
  PurchasesInsuranceItemType[]
> {
  return request.get({
    path: '/purchases/insurances',
  })
}

export function getUserPurchasesCars(): Promise<PurchasesCarsItemType[]> {
  return request.get({
    path: '/purchases/cars',
  })
}

export function getUserPurchasesOthers(): Promise<PurchasesOthersItemType[]> {
  return request.get({
    path: '/purchases/other',
  })
}

export function getUserPurchasesOthersDetails(
  document: string,
  dealer: number,
): Promise<CardDetailsType> {
  return request.get({
    path: `/purchases/other/${document}?dealer=${dealer}`,
  })
}

/** Cabinet Bookmarks**/

export function getUserBookmarks(): Promise<UserBookmarksType[]> {
  return request.get({
    path: '/bookmarks/cars',
  })
}

export function addCarToBookmarks(
  payload: IBookmarksItemPayload,
): Promise<{ success: boolean }> {
  return request.post({
    path: '/bookmarks/cars',
    body: payload,
  })
}

export function deleteCarFromBookmarks(
  payload: IBookmarksItemPayload,
): Promise<{ success: boolean }> {
  return request.delete({
    path: '/bookmarks/cars',
    body: payload,
  })
}

export function deleteAllCarsFromBookmarks(): Promise<{ success: boolean }> {
  return request.delete({
    path: '/bookmarks/cars/delete-all',
  })
}

export function deleteSoldCarsFromBookmarks(): Promise<{ success: boolean }> {
  return request.delete({
    path: '/bookmarks/cars/delete-sold',
  })
}

/** Cabinet  Requests**/

export function getUserRequests(): Promise<IUserRequestsType[]> {
  return request.get({
    path: '/requests',
  })
}

/** Cabinet Car View**/

export function getCarView(carId: string): Promise<CarsItemType> {
  return request.get({
    path: `/cars/${carId}`,
  })
}

export function getCarHistory(carId: string): Promise<CarsItemHistoryType[]> {
  return request.get({
    path: `/cars/${carId}/history`,
  })
}

export function getCarHistoryDetails(
  carId: string,
  dealer: number,
  document: string,
): Promise<CardDetailsType> {
  return request.get({
    path: `/cars/${carId}/history/${document}?dealer=${dealer}`,
  })
}

export function getCarRecommendations(
  carId: string,
): Promise<CarsItemRecommendationsType[]> {
  return request.get({
    path: `/cars/${carId}/recommendations`,
  })
}

export function getCarServiceCompanies(
  carId: string,
): Promise<CarsItemServiceCompaniesType[]> {
  return request.get({
    path: `/cars/${carId}/companies`,
  })
}

export function getCarTO(
  carId: string,
  dealerId?: number,
): Promise<CarsItemTOType[]> {
  return request.get({
    path: `/cars/${carId}/to${dealerId ? `?dealer=${dealerId}` : ''}`,
  })
}

export function getCarTODetails(
  carId: number,
  toId: number,
  dealerId?: number,
): Promise<CardDetailsType> {
  return request.get({
    path: `/cars/${carId}/to/${toId}${dealerId ? `?dealer=${dealerId}` : ''}`,
  })
}

export function updateCarPhoto(
  payload: AddPhotoPayload,
  carId: string,
): Promise<{ success: boolean }> {
  return request.post({ path: `/cars/${carId}/photo`, body: payload })
}

export function toggleShowCar(carId: number): Promise<{ archived: boolean }> {
  return request.post({ path: `/cars/${carId}/toggle`, body: {} })
}

export function getCabinetBrands(): Promise<CabinetBrandsPayload[]> {
  return request.get({
    path: `/service/brands`,
  })
}

export function getServiceCars(): Promise<ServiceCarsPayload[]> {
  return request.get({
    path: `/service/cars`,
  })
}

export function getServiceAvailableTimeSlots(
  dealerId: number,
  date: string,
  carId: Nullable<number>,
  to: Nullable<number | string>,
  recommended: boolean,
): Promise<ServiceAvailableTimeSlotsPayload> {
  return request.get({
    path: `/service/timeslots?dealer=${dealerId}&date=${date}&car=${carId}&to=${to}&recommended=${recommended}`,
  })
}

export function getServiceTO(
  carId: number,
  dealerId?: number,
): Promise<ServiceTOPayload[]> {
  return request.get({
    path: `/cars/${carId}/to${dealerId ? `?dealer=${dealerId}` : ''}`,
  })
}

export function getServiceTODetails(
  carId: number,
  toId: number | string,
  dealerId?: number,
): Promise<ServiceTOPayloadDetails> {
  return request.get({
    path: `/cars/${carId}/to/${toId}${dealerId ? `?dealer=${dealerId}` : ''}`,
  })
}
