import React from 'react'

import Cabinet from '@/modules/Cabinet'

function Bonuses() {
  return (
    <Cabinet activeTab={'BONUSES'}>BONUSES</Cabinet>
  )
}

export default Bonuses
