import React, { useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'
import Spinner from '@/components/Spinner'
import LoginFormContainer from '@/modules/Login/components/LoginWindow/components/LoginForm/LoginForm.container'

function LoginWindow() {
  const [isLoadingAuth] = useState<boolean>(false)
  return (
    <Component>
      {isLoadingAuth && (
        <ComponentInner>
          <Spinner absolute={true} isSecondary={true} />
        </ComponentInner>
      )}
      <LoginContent>
        <LoginFormContainer />
      </LoginContent>
    </Component>
  )
}

export default LoginWindow

const Component = styled.div`
  display: flex;
  max-width: 760px;
  width: 100%;
  margin: auto;
  flex: 0 0 auto;
  background-color: ${props => props.theme.white};
  padding: 40px;
  box-shadow:
    0 0 80px 0 #d7e8ff,
    0 0 20px rgb(0 0 0 / 20%);
  position: relative;

  ${media.mobile(css`
    margin: 0;
    height: 100%;
    padding: 0;
    box-shadow: none;
  `)}
`

const ComponentInner = styled.div`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: ${props => props.theme.white};
`

const LoginContent = styled.div`
  flex: 0 0 370px;
  padding: 0 30px;

  ${media.mobile(css`
    flex: auto;
    padding: 0;
  `)}
`
