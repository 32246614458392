import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { css } from '@emotion/react'

import { MenuItemProps } from './types'

import { ReactComponent as ChevronRightIcon } from '@/assets/svg/chevron-right-24dp.svg'

function MenuItem({ link, label, isNewTab, children }: MenuItemProps) {
  const [isOpen, setIsOpen] = useState(false)

  function handleSubMenuToggle() {
    setIsOpen(!isOpen)
  }

  return (
    <Component>
      {children ? (
        <>
          <SubMenuLinkWrapper isOpen={isOpen}>
            <MenuLinkWrapper>
              <MenuLink to={link} target={isNewTab ? '_blank' : '_self'}>
                <MenuLabel>{label}</MenuLabel>
              </MenuLink>
            </MenuLinkWrapper>

            <SubMenuToggle
              onClick={event => {
                event.stopPropagation()
                handleSubMenuToggle()
              }}
            >
              <StyledChevronRightIcon />
            </SubMenuToggle>
          </SubMenuLinkWrapper>
          <SubMenu isOpen={isOpen}>
            <GoBackButton
              onClick={event => {
                event.stopPropagation()
                handleSubMenuToggle()
              }}
            >
              <GoBackButtonIcon>
                <StyledChevronRightIcon />
              </GoBackButtonIcon>
              <GoBackButtonLabel>{label}</GoBackButtonLabel>
            </GoBackButton>

            <Content>{children}</Content>
          </SubMenu>
        </>
      ) : (
        <MenuLink to={link} target={isNewTab ? '_blank' : '_self'}>
          <MenuLabel>{label}</MenuLabel>
        </MenuLink>
      )}
    </Component>
  )
}

export default MenuItem

const Component = styled.li``

const MenuLinkWrapper = styled.div`
  flex: 1;
`

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.gray200};
`

const MenuLabel = styled.span`
  display: block;
  cursor: pointer;

  flex: 1 1 auto;

  padding: 15px 20px;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;

  color: ${props => props.theme.black200};

  transition: 0.15s;
`

const SubMenuLinkWrapper = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: none;
    `};
`

const SubMenuToggle = styled.button`
  position: relative;

  display: flex;
  align-self: stretch;
  align-items: stretch;
  justify-content: center;

  width: 100%;
  max-width: 80px;

  padding: 10px 20px;
  border-bottom: 1px solid ${props => props.theme.gray200};

  &::before {
    content: '';
    position: absolute;
    top: 5px;
    left: 0;
    bottom: 5px;
    border-left: 1px solid ${props => props.theme.gray200};
  }

  svg {
    margin: auto;
  }
`

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  fill: ${props => props.theme.main};
`

const SubMenu = styled.div<{ isOpen: boolean }>`
  display: none;
  width: 100%;
  background: ${props => props.theme.white};

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `};
`

const GoBackButton = styled.button`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 15px 20px 15px 45px;

  text-align: start;
`

const GoBackButtonIcon = styled.span`
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%) rotate(180deg);

  display: flex;
  align-items: center;
  justify-content: center;
`

const GoBackButtonLabel = styled(MenuLabel)`
  padding: 0;
`

const Content = styled.div`
  padding: 10px 20px 20px;
  border-bottom: 1px solid ${props => props.theme.gray200};
`
