import React, { useState } from 'react'
import { Formik, FormikErrors, FormikHelpers } from 'formik'

import { addEmail } from '@/services/users/users-service'
import AddEmailForm, {
  AddEmailFormValues,
} from '@/components/PersonalCabinetForms/AddEmailForm/AddEmailForm'
import { convertRequestErrorToMap } from '@/utils/common'
import { useProfile } from '@/providers/Profile'

interface Props {
  closeModal: () => void
}

function AddEmailFormContainer({ closeModal }: Props) {
  const { getProfileDataAction } = useProfile()
  const [isEmailSend, setEmailSend] = useState<string | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)

  function handleEmailSend(
    values: AddEmailFormValues,
    formikHelpers: FormikHelpers<AddEmailFormValues>,
  ) {
    addEmail(values)
      .then(() => {
        formikHelpers.resetForm()
        setEmailSend(values.email)
      })
      .catch(error => {
        const errorMap = convertRequestErrorToMap(
          error,
        ) as FormikErrors<AddEmailFormValues>
        formikHelpers.setErrors(errorMap)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function handleCheckCode(
    values: AddEmailFormValues,
    formikHelpers: FormikHelpers<AddEmailFormValues>,
  ) {
    addEmail(values)
      .then(() => {
        getProfileDataAction().finally(() => {
          setLoading(false)
          closeModal()
        })
      })
      .catch((error: any) => {
        setLoading(false)
        const errorMap = convertRequestErrorToMap(
          error,
        ) as FormikErrors<AddEmailFormValues>
        formikHelpers.setErrors(errorMap)
      })
  }

  function handleSubmit(
    values: AddEmailFormValues,
    formikHelpers: FormikHelpers<AddEmailFormValues>,
  ) {
    setLoading(true)
    isEmailSend
      ? handleCheckCode(
          {
            email: isEmailSend,
            code: values.code,
          },
          formikHelpers,
        )
      : handleEmailSend({ ...values, email: values.email }, formikHelpers)
  }

  return (
    <Formik<AddEmailFormValues>
      onSubmit={handleSubmit}
      initialValues={{
        email: '',
        code: null,
      }}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {formProps => (
        <AddEmailForm
          {...formProps}
          isLoading={isLoading}
          isEmailSend={isEmailSend}
        />
      )}
    </Formik>
  )
}

export default AddEmailFormContainer
