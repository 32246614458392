import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { StringField } from '@/typings/common'
import { media } from '@/utils/mixin'

interface Props {
  title: StringField
  description?: StringField
  percentList?: Array<unknown>
  children?: React.ReactNode
}

function InformationCard({ title, children, description, percentList }: Props) {
  function getPercent(dataArray: any) {
    const dataArrayLength = dataArray.length
    const dataArrayNoEmpty = dataArray.filter(Boolean).length

    return Math.ceil((100 / dataArrayLength) * dataArrayNoEmpty)
  }

  return (
    <Card>
      <Content>
        <Percent percent={getPercent(percentList) ?? 0}>
          <PercentLabel>{getPercent(percentList)}%</PercentLabel>
        </Percent>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        <Information>{children}</Information>
      </Content>
    </Card>
  )
}

const Card = styled.div`
  position: relative;
  background: ${props => props.theme.white};
`

const Percent = styled.div<{ percent: number }>`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 14px;
  line-height: 20px;

  padding: 10px 15px;
  border-radius: 0 0 0 10px;
  background-color: #e8f1fd;
  color: ${props => props.theme.blue100};
  overflow: hidden;

  ${media.mobile(css`
    display: inline-block;
    position: relative;
    top: auto;
    right: auto;
    border-radius: 0;
    min-width: 160px;
    padding: 0 0 5px;
    margin: 0 0 7px;
    border-bottom: 4px solid #c7deff;
    background: 0 0;
    overflow: visible;
  `)};

  ${props =>
    props.percent === 100
      ? css`
          color: #1db068;
          background-color: #cbe6bc;

          ${media.mobile(css`
            border-color: #cbe6bc !important;
          `)}
        `
      : props.percent === 0
        ? css`
            background-color: #f9dede;

            ${media.mobile(css`
              border-color: #f9dede !important;
            `)}
          `
        : css`
            &:before {
              position: absolute;
              content: '';
              top: 0;
              left: ${props.percent}%;
              right: 0;
              bottom: 0;
              background-color: #c7deff;
              border-left: 25px solid #d8e7fe;
              border-top: 25px solid transparent;
              border-bottom: 25px solid transparent;
              margin-top: -5px;
              margin-left: -15px;

              ${media.mobile(css`
                display: none;
              `)}
            }

            &:after {
              position: absolute;
              content: '';
              top: 50%;
              left: ${props.percent + 20}%;
              border-left: 23px solid #e8f1fd;
              border-top: 25px solid transparent;
              border-bottom: 25px solid transparent;
              margin-top: -25px;
              margin-left: -33px;
              z-index: 1;

              ${media.mobile(css`
                background: #e8f1fd;
                border: none;
                top: auto;
                left: auto;
                right: 0;
                bottom: 0;
                margin: 0 0 -4px;
                padding: 0;
                height: 4px;
                width: calc(100% - ${props.percent}%);
              `)}
            }
          `}
`

const PercentLabel = styled.span`
  display: block;
  position: relative;
  z-index: 2;
`

const Content = styled.div`
  padding: 30px;

  ${media.mobile(css`
    padding: 20px;
  `)}
`

const Title = styled.h2`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 12px;
`

const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.blue100};
  font-style: italic;
  margin: 12px 0;
  max-width: 300px;

  ${media.mobile(css`
    margin: 10px 0;
  `)}
`

const Information = styled.div`
  margin-top: 30px;

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`

export default InformationCard
