import React from 'react'
import styled from '@emotion/styled/macro'

import { googleEvent } from '@/utils/events'

function DesktopPhone() {
  const onPhoneClick = () => {
    googleEvent('')
  }

  const onPhoneSubmit = () => {
    window.location.hash = 'cbh-open'
  }

  return (
    <Component>
      <Link onClick={onPhoneClick} href={'tel:+375296770099'}>
        +375 29 677-00-99
      </Link>
      <Button onClick={onPhoneSubmit}>Заказать звонок</Button>
    </Component>
  )
}

export default DesktopPhone

const Component = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-self: stretch;
  margin-left: 20px;
  flex-direction: column;
  justify-content: center;
`

const Button = styled.button`
  color: ${props => props.theme.white};
  text-decoration: underline;
  font-weight: 700;
  margin-top: 5px;

  &:hover {
    text-decoration: none;
  }
`

const Link = styled.a`
  display: flex;
  align-items: center;
  color: ${props => props.theme.white};
`
