import React from 'react'
import styled from '@emotion/styled'

import Header from './components/Header/Header'

import Footer from '@/components/Layout/components/Footer'
import { DealersProvider } from '@/providers/Dealers'

interface Props {
  children?: React.ReactNode
}

function Layout({ children }: Props) {
  return (
    <Component>
      <DealersProvider>
        <Header />
        <Main>{children}</Main>
        <Footer />
      </DealersProvider>
    </Component>
  )
}

export default Layout

const Component = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 200px;
`
