import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'

import { ReactComponent as FlagIcon } from '@/assets/svg/flag.svg'
import { media } from '@/utils/mixin'
import { useProfile } from '@/providers/Profile'

function Promo() {
  const { profile } = useProfile()

  return (
    <Component>
      <ComponentContainer>
        <Icon>
          <FlagIcon />
        </Icon>
        <Body>
          <Text>
            Ваш профиль заполнен на{' '}
            <IDC>{profile.data?.profilePercentFullness ?? 0}%</IDC>
          </Text>
          <StyledLink to={'/personal'}>Заполнить</StyledLink>
        </Body>
      </ComponentContainer>
    </Component>
  )
}

export default Promo

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  width: 50%;
  padding: 0 15px;
  margin-bottom: 30px;

  ${media.tablet(css`
    width: 100%;
    padding: 0;
  `)}
`

const ComponentContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  padding-top: 28px;
  border-top: 1px solid ${props => props.theme.gray900};

  ${media.tablet(css`
    flex-direction: row-reverse;
  `)}
`

const Icon = styled.div`
  width: 36px;
  margin-right: 12px;

  ${media.tablet(css`
    margin-left: 12px;
  `)}
`

const Body = styled.div`
  flex: 1;
  color: ${props => props.theme.blue100};
  font-size: 14px;
  line-height: 20px;
`

const Text = styled.div`
  color: ${props => props.theme.blue100};
  font-size: 14px;
  line-height: 20px;
`

const IDC = styled.span`
  color: ${props => props.theme.red100};
`

const StyledLink = styled(Link)`
  display: inline-block;
  margin-top: 8px;
  color: ${props => props.theme.gray500};
  text-transform: uppercase;

  font-size: 14px;
  line-height: 20px;

  border-bottom: 1px solid transparent;
  transition:
    color 0.15s,
    border 0.15s;

  &:hover {
    color: ${props => props.theme.gray500};
    border-bottom: 1px solid ${props => props.theme.gray500};
  }
`
