import React from 'react'
import { Form, FormikProps } from 'formik'

import CabinetFormTemplate from '../components/CabinetFormTemplate'
import CabinetFormField from '../components/CabinetFormField'
import CabinetTextInputFormik from '../components/CabinetTextInput/CabinetTextInput.formik'
import CabinetDataInputFormik from '../components/CabinetDataInput'
import CabinetTabs from '../components/CabinetTabs'
import CabinetButton from '../components/CabinetButton'
import {
  FormAction,
  FormActionCol,
  FormCol,
  FormRow,
} from '../components/CabinetForm.style'

import { PersonalDataPayload } from '@/services/users/typings'
import { OptionType, StringField } from '@/typings/common'

export type PersonalDataFormValues = PersonalDataPayload

type Props = FormikProps<PersonalDataPayload> & {
  gender: StringField
  setGender: (value: StringField) => void
  setFormVisible: (value: boolean) => void
  birthdate: Date | null
  setBirthdate: (value: Date | null) => void
}

const GENDER: Array<OptionType> = [
  {
    label: 'Мужской',
    value: 'MALE',
  },
  {
    label: 'Женский',
    value: 'FEMALE',
  },
]

function PersonalDataForm({
  handleSubmit,
  errors,
  gender,
  setGender,
  setFormVisible,
  birthdate,
  setBirthdate,
}: Props) {
  return (
    <Form autoComplete='off' noValidate>
      <CabinetFormTemplate title={'Общая группа'}>
        <FormRow>
          <FormCol>
            <CabinetFormField label={'Ваше имя'} error={errors.firstName}>
              <CabinetTextInputFormik
                id='First name'
                type='text'
                name='firstName'
                maxLength={20}
              />
            </CabinetFormField>
          </FormCol>

          <FormCol>
            <CabinetFormField label={'Ваше отчество'} error={errors.patronymic}>
              <CabinetTextInputFormik
                id='Patronymic'
                type='text'
                name='patronymic'
                maxLength={20}
              />
            </CabinetFormField>
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol>
            <CabinetFormField label={'Ваша фамилия'} error={errors.lastName}>
              <CabinetTextInputFormik
                id='Last Name'
                type='text'
                name='lastName'
                maxLength={20}
              />
            </CabinetFormField>
          </FormCol>

          <FormCol>
            <CabinetFormField label={'Дата рождения'} error={errors.birthdate}>
              <CabinetDataInputFormik
                inputName={'birthdate'}
                data={birthdate}
                setData={(value: Date | null) => setBirthdate(value)}
              />
            </CabinetFormField>
          </FormCol>
        </FormRow>

        <FormRow>
          <FormCol>
            <CabinetFormField label={'Пол'} error={errors.gender}>
              <CabinetTabs
                list={GENDER}
                activeValue={gender}
                setActiveValue={(value: StringField) => setGender(value)}
              />
            </CabinetFormField>
          </FormCol>
        </FormRow>

        <FormAction>
          <FormActionCol>
            <CabinetButton variant={'contained'} onClick={() => handleSubmit()}>
              сохранить
            </CabinetButton>
          </FormActionCol>

          <FormActionCol>
            <CabinetButton
              variant={'outlined'}
              onClick={() => setFormVisible(false)}
            >
              отменить
            </CabinetButton>
          </FormActionCol>
        </FormAction>
      </CabinetFormTemplate>
    </Form>
  )
}

export default PersonalDataForm
