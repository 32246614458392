import { css, Theme } from '@emotion/react'

export const ScrollStyles = ({ theme }: { theme: Theme }) => css`
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: ${theme.gray1600_alpha50};
    border: 1px solid ${theme.gray1300_alpha50_1};
    border-radius: 29px;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: ${theme.gray900};
    border-radius: 29px;
  }

  /* firefox */

  scrollbar-width: thin;
`
