import React from 'react'
import styled from '@emotion/styled/macro'

import Spinner from '@/components/Spinner'
import { useProfile } from '@/providers/Profile'

function CabinetLoader() {
  const { pageLoading } = useProfile()

  return pageLoading ? (
    <Component>
      <Spinner absolute={true} isCabinet={true} />
    </Component>
  ) : null
}

const Component = styled.div`
  position: relative;
  min-height: 60vh;
  width: 100%;
`

export default CabinetLoader
