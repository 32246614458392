import React from 'react';

import { ButtonVariant } from './CabinetButton.style';
import * as S from './CabinetButton.style';

type CommonProps = {
  variant?: ButtonVariant;
  disabled?: boolean;
};

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  CommonProps;

function CabinetButton({ type = 'button', ...rest }: ButtonProps) {
  return <S.StyledCabinetButton type={type} {...rest} />;
}

export default CabinetButton;
