import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Cabinet from '@/modules/Cabinet'
import { media } from '@/utils/mixin'
import { useProfile } from '@/providers/Profile'
import { CabinetCarsProvider } from '@/providers/CabinetCars/CabinetCarsProvider'
import { FETCH_STATUSES } from '@/typings/common'
import CabinetError from '@/modules/Cabinet/components/CabinetError'
import Filters from '@/modules/Cabinet/components/CabinetDataTable/components/TableContent/components/Filters'
import CarsList from '@/modules/Cars/components/CarsList'

function Cars() {
  const {
    pageLoading,
    profile,
    cars,
    updatePageLoading,
    getUserCarsAction,
    getBrandsAction,
  } = useProfile()
  const cabinetCars = cars.data

  React.useLayoutEffect(() => {
    async function fetchData() {
      await Promise.all([getUserCarsAction(), getBrandsAction()])
    }

    updatePageLoading(true)

    fetchData()
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        updatePageLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Cabinet activeTab={'CARS'}>
      {!pageLoading &&
        (profile.status === FETCH_STATUSES.SUCCESS ? (
          <CabinetCarsProvider>
            <Content>
              <Top>
                <Title>Мои автомобили</Title>
                {cabinetCars && <Count>{cabinetCars.length} авто</Count>}
              </Top>
              <Filters />
              <CarsList carsList={cabinetCars} />
            </Content>
          </CabinetCarsProvider>
        ) : (
          <CabinetError text='Ошибка получения профиля' />
        ))}
    </Cabinet>
  )
}

export default Cars

const Content = styled.div`
  position: relative;
  margin-top: 24px;

  ${media.tablet(css`
    margin-top: 20px;
  `)}

  ${media.tabletSmall(css`
    margin-top: 24px;
  `)}

  ${media.mobile(css`
    margin: 4px 20px 0;
  `)};
`

const Top = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.h2`
  font-weight: 700;
  font-size: 40px;
  line-height: 52px;
  color: ${props => props.theme.black100};
  margin-right: 20px;

  ${media.tabletSmall(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`

const Count = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.blue100};

  ${media.tablet(css`
    font-size: 14px;
    line-height: 20px;
  `)}
`
