import React from 'react'
import styled from '@emotion/styled'

import { ReactComponent as FacebookIcon } from '@/assets/svg/socials-footer/facebook.svg'
import { ReactComponent as YoutubeIcon } from '@/assets/svg/socials-footer/youtube.svg'
// import { ReactComponent as OdnoklassnikiIcon } from '@/assets/svg/socials-footer/odnoklassniki.svg'
// import { ReactComponent as TwitterIcon } from '@/assets/svg/socials-footer/twitter.svg'
import { ReactComponent as InstagramIcon } from '@/assets/svg/socials-footer/instagram.svg'
import { ReactComponent as VkIcon } from '@/assets/svg/socials-footer/vk.svg'

const socialsList: { link: string; icon: JSX.Element }[] = [
  { link: 'https://www.facebook.com/atlantauto', icon: <FacebookIcon /> },
  { link: 'https://www.youtube.com/atlantmlive', icon: <YoutubeIcon /> },
  { link: 'https://www.instagram.com/atlantmauto', icon: <InstagramIcon /> },
  { link: 'https://vk.com/atlantmby', icon: <VkIcon /> },
]

function Socials() {
  return (
    <Component>
      {socialsList.map((item, index) => (
        <Item key={index}>
          <Link href={item.link} target='_blank'>
            {item.icon}
          </Link>
        </Item>
      ))}
    </Component>
  )
}

export default Socials

const Component = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
`

const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
`

const Link = styled.a`
  display: inline-block;

  &:hover {
    opacity: 0.6;
  }
`
