import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Slider from './components/Slider'
import NoCars from './components/NoCars'
import Logout from './components/Logout'
import Promo from './components/Promo'
import Actions from './components/Actions'
import Menu from './components/Menu'

import { media } from '@/utils/mixin'
import { ReactComponent as AccountIcon } from '@/assets/svg/account.svg'
import { ReactComponent as CloseIcon } from '@/assets/svg/close-24dp.svg'
import { isNotNullish } from '@/utils/common'
import { useAuth } from '@/providers/Auth'
import { useProfile } from '@/providers/Profile'

function MobileUser() {
  const [isOpen, setIsOpen] = useState(false)
  const { profile, preview } = useProfile()
  const { status } = useAuth()
  const isUserAuthorized = status === 'AUTHORIZED'

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  const slides =
    preview.data?.cars && preview.data.cars.length > 0 ? preview.data.cars : []
  const isUserCars = slides.length > 0

  return (
    <Component>
      {isUserAuthorized ? (
        <Button type='button' onClick={() => setIsOpen(!isOpen)}>
          <AccountIcon />
        </Button>
      ) : (
        <ButtonLink href={'/personal/login'}>
          <AccountIcon />
        </ButtonLink>
      )}
      <Panel isOpen={isOpen}>
        <Name>
          {profile.data?.name} {profile.data?.lastName}
        </Name>
        <CloseButton type='button' onClick={() => setIsOpen(!isOpen)}>
          <CloseIcon />
        </CloseButton>
      </Panel>
      <DropDownMenu isOpen={isOpen}>
        <DropDownMenuContainer>
          {isUserCars ? (
            <Slider slides={slides} />
          ) : preview.data?.availability?.cars ? (
            <NoCars infoType='archived' />
          ) : (
            <NoCars infoType='noCars' />
          )}
          <Actions />
          <Promo />

          <Menu
            items={[
              {
                label: 'Личные данные',
                url: '/personal',
              },
            ]}
          />

          <Menu
            items={[
              preview.data?.availability?.cars
                ? {
                    label: 'Мои автомобили',
                    url: '/personal/cars',
                  }
                : undefined,
              preview.data?.availability?.purchasesCars ||
              preview.data?.availability?.purchasesOther ||
              preview.data?.availability?.purchasesInsurances ||
              preview.data?.availability?.purchasesServices
                ? {
                    label: 'Мои покупки',
                    url: '/personal/purchases',
                  }
                : undefined,
              preview.data?.availability?.requests
                ? {
                    label: 'Мои заявки',
                    url: '/personal/requests',
                  }
                : undefined,
              preview.data?.availability?.bonuses
                ? {
                    label: 'Мои бонусы',
                    url: '/personal/bonuses',
                  }
                : undefined,
            ].filter(isNotNullish)}
          />

          <Logout />
        </DropDownMenuContainer>
      </DropDownMenu>
    </Component>
  )
}

export default MobileUser

const Component = styled.div`
  margin: 0 5px;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 40px;
  height: 40px;

  ${media.mobileSmall(css`
    width: 25px;
    height: 25px;
  `)}

  svg {
    width: 20px;
    height: 20px;
  }
`

const ButtonLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  width: 25px;
  height: 25px;

  @media (min-width: 376px) {
    width: 40px;
    height: 40px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`

const Panel = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 110;

  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 60px;

  padding: 0 20px;

  background: ${props => props.theme.blue};

  opacity: 0;
  visibility: hidden;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
    `};
`

const Name = styled.span`
  margin-right: 20px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 16px;
  line-height: 24px;

  color: ${props => props.theme.white};
`

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;

  color: ${props => props.theme.white};

  svg {
    width: 28px;
    height: 28px;
    fill: currentColor;
  }
`

const DropDownMenu = styled.div<{ isOpen: boolean }>`
  display: none;

  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  z-index: 300;

  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 60px);

  overflow-x: hidden;
  overflow-y: scroll;

  overscroll-behavior: contain;

  background: ${props => props.theme.white};

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `}
`

const DropDownMenuContainer = styled.div`
  padding: 20px;
`
