import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'

interface Props {
  state: boolean
  changeState: (value: boolean) => void
}

function ChangeField({ state, changeState }: Props) {
  function handlerClick() {
    changeState(!state)
  }

  return (
    <Block>
      <Field onClick={handlerClick}>Изменить</Field>
    </Block>
  )
}

const Block = styled.div`
  margin-top: 30px;

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`

const Field = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.main};
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border 0.15s;
  cursor: pointer;

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
  `)}

  &:hover {
    border-color: ${props => props.theme.main};
  }
`

export default ChangeField
