import React from 'react'
import { useSelect } from 'downshift'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { OptionType } from '@/typings/common'
import { ReactComponent as ChevronIcon } from '@/assets/svg/chevron-down-coloured.svg'
import { media } from '@/utils/mixin'

interface Props {
  value: OptionType
  onChange?: (newSelectedValue: OptionType) => void
  options: OptionType[]
}

function CabinetSelectForFilters({ value, options, onChange }: Props) {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    selectedItem: value,
    items: options,
    onSelectedItemChange: ({ selectedItem }) => {
      onChange?.(selectedItem ?? options[0])
    },
  })

  return (
    <Component>
      <Display type='button' {...getToggleButtonProps()} isOpen={isOpen}>
        <SelectedItem>{selectedItem?.label}</SelectedItem>
        <StyledArrowIcon isOpen={isOpen} />
      </Display>
      <DropdownMenu {...getMenuProps()} isOpen={isOpen}>
        {options.length > 0 ? (
          <OptionList>
            {options.map((option, index) => (
              <OptionItem
                key={`${option.value}${index}`}
                {...getItemProps({
                  item: option,
                  index,
                  isSelected: option.value === value?.value,
                })}
              >
                {option.label}
              </OptionItem>
            ))}
          </OptionList>
        ) : (
          <NoOptions>No options</NoOptions>
        )}
      </DropdownMenu>
    </Component>
  )
}

const Component = styled.div`
  position: relative;
`

const Display = styled.button<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  color: ${props => props.theme.black100};
  transition: 0.15s;
  cursor: pointer;

  ${media.mobile(css`
    cursor: default;
  `)}

  &:hover {
    color: ${props => props.theme.main};
  }
`

const SelectedItem = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  vertical-align: top;
  max-width: 100%;
  overflow: hidden;
  display: block;
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition:
    0.15s,
    color 0s,
    font 0s;
`

const StyledArrowIcon = styled(ChevronIcon)<{ isOpen: boolean }>`
  margin-left: 10px;
  width: 11px;
  height: 7px;
  transition: 0.3s;

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `};
`

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 5px;
  padding: 30px;
  border: 1px solid ${props => props.theme.gray400};
  background: ${props => props.theme.white};
  color: ${props => props.theme.black100};
  box-shadow: 0 5px 30px 0 rgb(23 43 77 / 10%);
  cursor: default;
  word-wrap: break-word;
  word-break: break-word;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  transition:
    0.15s,
    left 0s;
  opacity: 0;
  visibility: hidden;
  z-index: 4;

  ${media.mobile(css`
    padding: 20px;
    width: 100%;
  `)}

  ${({ isOpen }) =>
    isOpen &&
    css`
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    `};
`

const OptionList = styled.ul`
  margin: -8px 0;
`

const OptionItem = styled.li<{ isSelected: boolean }>`
  display: block;
  padding: 8px 30px;
  margin: 0 -30px;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.blue100};
  outline: 0;
  border: none;
  cursor: pointer;
  transition: 0.15s;
  white-space: nowrap;

  ${media.tabletSmall(css`
    cursor: default;
  `)}

  ${media.mobile(css`
    margin: 0 -20px;
    padding: 8px 20px;
  `)}

  &:hover {
    color: ${props => props.theme.blue100};
    outline: 0;
    background: ${props => props.theme.gray200};
  }
`

const NoOptions = styled.div``

export default CabinetSelectForFilters
