import React from 'react';
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'

import Convert from './components/Convert';
import Cashback from './components/Cashback';


import ShapeIcon from '@/assets/svg/shape.svg'
import { useProfile } from '@/providers/Profile'

interface IBonusCard {
  isBonusPage?: boolean;
}

function BonusCard({ isBonusPage }: IBonusCard) {
  const {bonuses} = useProfile()

  if (!bonuses || !bonuses.data) return null

  return (
    <Card>
      <Background>
        <img loading="lazy" src={ShapeIcon} alt="" />
      </Background>
      <Content>
        <Top>
          {isBonusPage || !bonuses.data ? (
            <Text>Бонусный счет</Text>
          ) : (
            <BonusPageLink to={'/personal/bonuses'}>
              Бонусный счет
            </BonusPageLink>
          )}
        </Top>
        <Convert bonusesValue={bonuses.data?.bonuses ?? 0} />
        {bonuses.data?.cashback?.status ||
        bonuses.data?.cashback?.percent ||
        bonuses.data?.cashback?.dateTo ? (
          <Cashback bonuses={bonuses.data} />
        ) : null}
      </Content>
    </Card>
  );
}

const Card = styled.div`
  position: relative;
  width: 100%;
  background-color: ${props => props.theme.main};
  padding: 30px;
  border-radius: 20px;
  color: ${props => props.theme.white};
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  img {
    width: 90px;
    height: 90px;
  }
`;

const Content = styled.div``;

const Top = styled.div`
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

const Text = styled.span``;

const BonusPageLink = styled(Link)`
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background: transparent;
    transition: all 0.15s linear;
  }

  &:hover {
    &:before {
      background: ${props => props.theme.white};
    }
  }
`;

export default BonusCard;
