import { useState } from 'react'
import { useSelect } from 'downshift'

import { OptionType } from '@/typings/common'
import { locationData } from '@/components/Layout/components/Header/components/Location/constants'
import cookie, { getCityFromCookie } from '@/utils/cookie'
import { useIsomorphicLayoutEffect } from '@/hooks/useIsomorphicLayoutEffect'

export const useLocationData = () => {
  const [selectedCity, setSelectedCity] = useState<OptionType>(locationData[4])

  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getItemProps,
    getMenuProps,
  } = useSelect({
    selectedItem: selectedCity,
    items: locationData,
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        setSelectedCity(selectedItem)
        cookie.set('selectedCity', JSON.stringify(selectedItem))
      }
    },
  })

  useIsomorphicLayoutEffect(() => {
    const selectedLocationFromCookie = getCityFromCookie()

    if (selectedLocationFromCookie) {
      setSelectedCity(selectedLocationFromCookie)
    }
  }, [])
  return {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getItemProps,
    getMenuProps,
  }
}
