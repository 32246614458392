import React, { useCallback, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { css } from '@emotion/react'

import { useIsomorphicLayoutEffect } from '@/hooks/useIsomorphicLayoutEffect'
import {
  headerMenu,
  HeaderVariables,
} from '@/components/Layout/components/Header/components/Menu/constants'
import { MenuItemType } from '@/typings/common'
import CarsDropdown from '@/components/Layout/components/Header/components/Menu/MenuDesktop/components/CarsDropdown'
import ServiceDropdown from '@/components/Layout/components/Header/components/Menu/MenuDesktop/components/ServiceDropdown'
import OtherDropdown from '@/components/Layout/components/Header/components/Menu/MenuDesktop/components/OtherDropdown'

function MenuDesktop() {
  const containerRef = useRef<HTMLElement | null>(null)
  const listRef = useRef<HTMLUListElement | null>(null)

  const [otherItems, setOtherItems] = useState<MenuItemType[]>([])
  const [isMount, setMount] = useState<boolean>(true)

  const truncate = useCallback(() => {
    if (!containerRef.current || !listRef.current) {
      return
    }

    const childNodes = Array.from(
      listRef.current.children,
    ) as Array<HTMLElement>

    for (let node of childNodes) {
      node.hidden = true
    }

    if (childNodes.length === 0) {
      return
    }

    for (let index = 0; index < childNodes.length; index++) {
      const item = childNodes[index]
      const nextItem = childNodes[index + 1]

      if (nextItem) {
        item.hidden = false
        nextItem.hidden = false

        const nextItemRect = nextItem.getBoundingClientRect()
        const containerRect = containerRef.current.getBoundingClientRect()

        nextItem.hidden = true

        if (Math.round(nextItemRect.right) >= Math.round(containerRect.right)) {
          item.hidden = true

          if (index > 0) {
            childNodes[index - 1].hidden = false
          }

          break
        }
      }
    }

    const hiddenItems = childNodes.filter(h => h.hidden).length - 1

    if (hiddenItems && childNodes.length > hiddenItems) {
      childNodes[childNodes.length - 1].hidden = false
    }

    let indexes: Array<number> = []

    childNodes.forEach((item, index) => {
      if (item.hidden && headerMenu[index]) {
        indexes.push(index)
      }
    })

    setOtherItems(indexes.map(index => headerMenu[index]))

    if (isMount) {
      setMount(false)
    }
  }, [isMount])

  useIsomorphicLayoutEffect(() => {
    if (isMount) {
      setMount(false)
    }

    truncate()

    window.addEventListener('resize', truncate, false)
    return () => {
      window.removeEventListener('resize', truncate, false)
    }
  }, [truncate, isMount])

  return (
    <Component ref={containerRef} isOpacity={isMount}>
      <NavList ref={listRef}>
        {headerMenu.map((menuItem, index) => {
          const isCarsDropdown = menuItem.label.includes(HeaderVariables.Cars)
          const isServiceDropdown = menuItem.label.includes(
            HeaderVariables.Service,
          )
          if (isCarsDropdown) {
            return (
              <NavItem key={index}>
                <CarsDropdown menuItem={menuItem} />
              </NavItem>
            )
          }

          if (isServiceDropdown) {
            return (
              <NavItem key={index}>
                <ServiceDropdown menuItem={menuItem} />
              </NavItem>
            )
          }

          return (
            <NavItem key={index}>
              <NavLink
                to={menuItem.link ?? '/'}
                target={menuItem.isNewTab ? '_blank' : '_self'}
              >
                {menuItem.label}
              </NavLink>
            </NavItem>
          )
        })}

        <NavItem>
          <OtherDropdown
            items={otherItems.map(({ label, link, isNewTab }) => ({
              label:
                label.indexOf(':') === -1
                  ? label
                  : label.substring(0, label.indexOf(':')).trim(),
              link: link ?? '',
              isNewTab,
            }))}
          />
        </NavItem>
      </NavList>
    </Component>
  )
}

export default MenuDesktop

const Component = styled.nav<{ isOpacity: boolean }>`
  display: flex;
  flex: 1 1 auto;
  align-self: stretch;
  align-items: stretch;
  opacity: ${({ isOpacity }) => (isOpacity ? 0 : 1)};
  overflow: ${({ isOpacity }) => (isOpacity ? 'hidden' : '')};
`

const NavList = styled.ul`
  display: flex;
  flex: 0 0 auto;
  align-items: stretch;
  margin: 0 auto;
`

const NavItem = styled.li`
  display: flex;
  flex: 1 1 auto;
  align-items: stretch;

  &[hidden] {
    display: none;
  }
`

const NavLink = styled(Link)<{ isOpen?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 21px 25px;
  border: none;
  color: ${props => props.theme.white};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.15s;
  white-space: nowrap;
  flex-wrap: nowrap;
  z-index: 91;

  &:hover {
    background: ${props => props.theme.white};
    color: ${props => props.theme.main};
  }

  ${props =>
    props.isOpen &&
    css`
      background: ${props.theme.white};
      color: ${props.theme.main};
    `};
`
