import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'

import { CabinetHeaderTabs } from '../../Cabinet'

import { CABINET_HEADER } from './constants'
import { getVisibleHeaderList } from './helpers'

import { media } from '@/utils/mixin'
import { MenuItemType } from '@/typings/common'
import { useProfile } from '@/providers/Profile'
import { ContentContainerSmall } from '@/components/common'

export type CabinetHeaderItemOption = MenuItemType & {
  name: string
}

interface Props {
  activeTab: CabinetHeaderTabs
  canActiveTabHover?: boolean
}

function CabinetHeader({ activeTab, canActiveTabHover }: Props) {
  const { preview } = useProfile()

  return (
    <Block>
      <ContentContainerSmall>
        <Nav>
          {getVisibleHeaderList(
            CABINET_HEADER,
            preview.data?.availability ?? null,
          ).map(navItem => (
            <NavLink
              key={navItem.id}
              to={navItem.link ?? ''}
              isActive={navItem.name === activeTab}
              canActiveTabHover={canActiveTabHover}
            >
              {navItem.label}
            </NavLink>
          ))}
        </Nav>
      </ContentContainerSmall>
    </Block>
  )
}

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${props => props.theme.white};

  scrollbar-color: ${props => props.theme.gray400} transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 0.375rem;

    &-track {
      background-color: transparent;
    }

    &-thumb {
      background-color: ${props => props.theme.gray400};
      border-radius: 20px;
    }
  }
  ${media.tablet(css`
    overflow-y: auto;
    justify-content: flex-start;
  `)};

  ${media.mobile(css`
    margin: 0 -20px;
  `)};
`

const NavLink = styled(Link)<{
  isActive: boolean
  canActiveTabHover?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;

  padding: 30px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: ${props => props.theme.black100};
  text-transform: uppercase;

  border-right: 1px solid ${props => props.theme.gray200};
  border-bottom: 2px solid transparent;

  cursor: pointer;
  transition: 0.15s;

  &:last-child {
    border-right: none;
  }

  &:hover {
    border-bottom-color: ${props => props.theme.main};
    color: ${props => props.theme.main};

    ${props =>
      media.mobile(css`
        border-bottom-color: transparent;
        color: ${props.theme.black100};
      `)}
  }

  ${media.tablet(css`
    flex: 0 0 25%;
    padding: 20px;
    border-bottom: none;
  `)}

  ${media.tabletSmall(css`
    flex: 0 0 34%;
    padding: 20px;
    border-bottom: none;
  `)}

  ${media.mobile(css`
    flex: 0 0 50%;
  `)}

  ${({ isActive, theme }) =>
    isActive &&
    css`
      pointer-events: none;
      color: ${theme.main};
    `};

  ${({ canActiveTabHover }) =>
    canActiveTabHover &&
    css`
      pointer-events: auto !important;
      cursor: pointer !important;
    `}
`

const Block = styled.div`
  background: ${props => props.theme.white};
  position: sticky;
  top: 0;
  z-index: 100;
  transition: 0s;

  box-shadow: 0 5px 30px 0 rgb(23 43 77 / 10%);

  ${media.tablet(css`
    top: 80px;
  `)}

  ${media.mobile(css`
    top: 60px;
  `)}
`

export default CabinetHeader
