import React, { useState } from 'react'
import { Formik, FormikErrors, FormikHelpers } from 'formik'

import {
  signIn,
  updatePersonalDataInTheProfile,
} from '@/services/users/users-service'
import { LoginFormPayload, PersonalDataPayload } from '@/services/users/typings'
import { useAuth } from '@/providers/Auth'
import LoginForm, {
  LoginFormValues,
} from '@/modules/Login/components/LoginWindow/components/LoginForm/LoginForm'
import { convertRequestErrorToMap } from '@/utils/common'

function LoginFormContainer() {
  const { signInAction } = useAuth()
  const [isPhoneSend, setPhoneSend] = useState<boolean>(false)
  const [isNew, setNew] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)

  async function handleSendPhone(values: { phone: string }) {
    setLoading(true)

    try {
      await signIn(values).then(() => {
        setPhoneSend(true)
      })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setLoading(false)
    }
  }

  async function handleCheckCode(values: LoginFormPayload) {
    setLoading(true)

    try {
      const response = await signInAction(values)

      if (response?.isNew) {
        setNew(response.isNew)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  async function handleSetName(values: PersonalDataPayload) {
    setLoading(true)

    try {
      await updatePersonalDataInTheProfile(values)
      window.location.reload()
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  async function handleSubmit(
    values: LoginFormValues,
    formikHelpers: FormikHelpers<LoginFormValues>,
  ) {
    try {
      !isPhoneSend
        ? await handleSendPhone({ phone: values.phone })
        : !isNew
          ? await handleCheckCode({
              phone: values.phone,
              code: values.code,
            })
          : await handleSetName({ firstName: values.firstName })
    } catch (error: any) {
      const errorMap = convertRequestErrorToMap(
        error,
      ) as FormikErrors<LoginFormValues>
      formikHelpers.setErrors(errorMap)
      console.log('error = ', error)
    }
  }

  return (
    <Formik<LoginFormValues>
      onSubmit={handleSubmit}
      initialValues={{
        phone: '',
        code: '',
        firstName: '',
      }}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {formProps => (
        <LoginForm
          {...formProps}
          isPhoneSend={isPhoneSend}
          isNew={isNew}
          isLoading={isLoading}
        />
      )}
    </Formik>
  )
}

export default LoginFormContainer
