import styled from '@emotion/styled'

export const FooterContainer = styled.div<{ width: number }>`
  max-width: ${props => props.width + 'px'};
  padding: 0 20px;
  margin: 0 auto;

  @media (min-width: 1200px) {
    padding: 0 40px;
  }
`
