import { ParsedResponseBody, RequestErrorType } from './types'

class RequestError extends Error {
  status: { code: number; text: string }
  body: ParsedResponseBody

  constructor(
    status: { code: number; text: string },
    body: ParsedResponseBody,
  ) {
    super(JSON.stringify({ status, body }, null, 2))

    this.status = status
    this.body = body

    console.log(this)
  }

  public asObject(): RequestErrorType {
    return {
      statusCode: this.status.code,
      errorMessage:
        this.body && typeof this.body === 'object' && 'message' in this.body
          ? this.body['message']
          : this.status.text,
    }
  }
}

export default RequestError
