import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'

interface Props {
  name: string
  text: string
  isChecked: boolean
  setChecked: (value: boolean) => void
}

function CabinetSwitch({ name, text, isChecked, setChecked }: Props) {
  return (
    <Switch>
      <Label htmlFor={name}>
        <Text>{text}</Text>
        <Toggle isChecked={isChecked} />
        <Input
          type={'checkbox'}
          id={name}
          onChange={() => setChecked(!isChecked)}
        />
      </Label>
    </Switch>
  )
}

const Switch = styled.div`
  display: flex;
  justify-content: center;
`

const Label = styled.label`
  display: flex;
  cursor: pointer;

  ${media.tabletSmall(css`
    cursor: default;
  `)}
`

const Input = styled.input`
  visibility: hidden;
  width: 0;
`

const Text = styled.span`
  padding-right: 10px;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.gray500};
`

const Toggle = styled.div<{ isChecked: boolean }>`
  position: relative;
  width: 42px;
  height: 24px;
  border-radius: 34px;
  background-color: ${props => props.theme.gray900};

  &:before {
    content: '';
    position: absolute;
    bottom: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    transition: 0.4s;
    background-color: ${props => props.theme.white};
    border-radius: 50%;
    box-shadow: 0 0 2px 0 ${props => props.theme.blue100};
  }

  ${({ isChecked, theme }) =>
    isChecked &&
    css`
      background-color: ${theme.main};

      &:before {
        transform: translateX(18px);
      }
    `}
`

export default CabinetSwitch
