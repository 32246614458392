import React from 'react'
import styled from '@emotion/styled'

import Dealer from '@/components/Layout/components/Footer/components/Main/components/Dealer'
import { useDealers } from '@/providers/Dealers'
import { FETCH_STATUSES } from '@/typings/common'

const Dealers = () => {
  const { state } = useDealers()

  if (!state || state.status !== FETCH_STATUSES.SUCCESS) {
    return null
  }

  return (
    <Component>
      {state.value.map((dealer, index) => (
        <DealerItem key={index}>
          <Dealer
            name={dealer.name ?? ''}
            url={dealer.url ?? ''}
            rating={dealer.rating}
            reviewsCount={dealer.reviewsCount}
          />
        </DealerItem>
      ))}
    </Component>
  )
}

export default Dealers

const Component = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-top: 16px;
`

const DealerItem = styled.li``
