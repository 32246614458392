import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { locationData } from '@/components/Layout/components/Header/components/Location/constants'
import { media } from '@/utils/mixin'
import { ReactComponent as NavigationIcon } from '@/assets/svg/navigation.svg'
import { useLocationData } from '@/components/Layout/components/Header/components/Location/hooks'

function LocationMobile() {
  const {
    isOpen,
    getMenuProps,
    getToggleButtonProps,
    getItemProps,
    selectedItem,
  } = useLocationData()
  return (
    <Component>
      <Select type='button' {...getToggleButtonProps()}>
        <NavigationIcon />
      </Select>

      <Content {...getMenuProps()} isOpen={isOpen}>
        {locationData.map((item, index) => {
          const isActive = item.value === selectedItem?.value

          return (
            <ListItem
              key={index}
              isActive={isActive}
              {...getItemProps({
                item: item,
                index,
              })}
            >
              {item.label}
            </ListItem>
          )
        })}
      </Content>
    </Component>
  )
}

export default LocationMobile

const Component = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const Select = styled.button`
  width: 40px;
  height: 40px;

  ${media.mobileSmall(css`
    width: 25px;
    height: 25px;
  `)}

  svg {
    width: 20px;
    height: 20px;
  }
`

const Content = styled.div<{ isOpen: boolean }>`
  z-index: 501;
  pointer-events: none;
  position: absolute;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  width: 90px;
  max-height: 0;
  background: ${props => props.theme.white};
  box-shadow: ${props => props.theme.blackShadow100};
  opacity: 0;
  transition: all 0.3s ease-in-out;

  ${media.mobileSmall(css`
    top: 30px;
  `)}

  ${({ isOpen }) =>
    isOpen &&
    css`
      pointer-events: auto;
      opacity: 1;
      max-height: 200px;
      transition: all 0.3s ease-in-out;
    `}
`

const ListItem = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  padding: 7px 10px;
  transition: all 0.3s ease-in-out;
  color: ${props => props.theme.black200};

  &:hover {
    color: ${props => props.theme.main};
    background: ${props => props.theme.white_1};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: 700;
    `}
`
