import React, { PropsWithChildren, useState } from 'react'

import { AuthContext } from '@/providers/Auth/AuthContext'
import { IAuth } from '@/providers/Auth/types'
import { FETCH_STATUSES } from '@/typings/common'
import { LoginFormPayload } from '@/services/users/typings'
import { checkCode, userApiService } from '@/services/users/users-service'

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  //-------------- STORE -------------- //

  const [state, setState] = useState<IAuth>({
    status: FETCH_STATUSES.IDLE,
    data: null,
  })
  const [status, setStatus] = useState<'AUTHORIZED' | 'UNAUTHORIZED'>(
    'UNAUTHORIZED',
  )

  //-------------- ACTIONS -------------- //

  const setAuthorizedAction = () => {
    setStatus('AUTHORIZED')
  }

  const setUnauthorizedAction = () => {
    setStatus('UNAUTHORIZED')
  }

  const signInAction = async (payload: LoginFormPayload) => {
    try {
      const response = await checkCode(payload)
      userApiService.setAccessToken(response.token)

      if (!response.isNew) {
        window.location.reload()
      }

      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const signOutThunk = async () => {
    window.location.href = '/'
    setStatus('UNAUTHORIZED')
    setState({ data: null, status: 'IDLE' })
    userApiService.setAccessToken(null)
    return Promise.resolve()
  }

  //-------------- PROVIDER -------------- //

  return (
    <AuthContext.Provider
      value={{
        state,
        status,
        signInAction,
        signOutThunk,
        setAuthorizedAction,
        setUnauthorizedAction,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
