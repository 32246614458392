import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import PersonalData from './components/Blocks/PersonalData'
import ContactDetails from './components/Blocks/ContactDetails'

import { media } from '@/utils/mixin'
import { useProfile } from '@/providers/Profile'

function InformationBlock() {
  const { profile } = useProfile()

  if (!profile || !profile.data) {
    return null
  }

  return (
    <Block>
      <Column>
        <Item>
          <PersonalData profileInformation={profile.data} />
        </Item>

        {/*<Item>*/}
        {/*  <SocialMediaForFastEntry profileInformation={profile.data} />*/}
        {/*</Item>*/}
      </Column>

      <Column>
        <Item>
          <ContactDetails profileInformation={profile.data} />
        </Item>

        {/*<Item>*/}
        {/*  <PassportDetails profileInformation={profile.data} />*/}
        {/*</Item>*/}

        {/*<Item>*/}
        {/*  <AddressLiving profileInformation={profile.data} />*/}
        {/*</Item>*/}
      </Column>
    </Block>
  )
}

export default InformationBlock

const Block = styled.div`
  display: flex;
  margin: 30px 0;

  ${media.mobile(css`
    display: block;
    margin: 30px 0;
  `)}
`

const Column = styled.div`
  flex: 1 1 50%;
  max-width: 50%;

  &:not(:first-child) {
    margin-left: 32px;

    ${media.mobile(css`
      margin-left: 0;
    `)}
  }

  ${media.mobile(css`
    flex: auto;
    max-width: none;
  `)}
`

const Item = styled.div`
  &:not(:first-child) {
    margin-top: 32px;

    ${media.mobile(css`
      margin-top: 0;
    `)}
  }
`
