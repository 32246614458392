import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import PrivateRoutes from '@/utils/PrivateRoutes'
import Index from '@/modules/Index'
import Cars from '@/modules/Cars'
import Requests from '@/modules/Requests'
import Purchases from '@/modules/Purchases'
import Service from '@/modules/Service'
import Bonuses from '@/modules/Bonuses'

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path='/cars' element={<Cars />} />
          <Route path='/requests' element={<Requests />} />
          <Route path='/purchases' element={<Purchases />} />
          <Route path='/service' element={<Service />} />
          <Route path='/bonuses' element={<Bonuses />} />
        </Route>
        <Route path='/' element={<Index />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router
