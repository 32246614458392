import React from 'react'

import cookie from '@/utils/cookie'
import PersonalData from '@/modules/PersonalData'
import Login from '@/modules/Login'
import Layout from '@/components/Layout'

function Index() {
  const token = cookie.get(
    process.env.REACT_APP_COOKIE_ACCESS_TOKEN_PARAM ?? 'accessToken',
  )

  return !!token ? (
    <Layout>
      <PersonalData />
    </Layout>
  ) : (
    <Login />
  )
}

export default Index
