import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { OptionType } from '@/typings/common'

interface Props<T> {
  list: Array<OptionType<T>>
  activeValue: T
  setActiveValue: (value: T) => void
}

function CabinetTabs<V>({ list, activeValue, setActiveValue }: Props<V>) {
  return (
    <Field>
      {list?.map((listItem, index) => {
        return (
          <Tab
            key={index}
            onClick={() => setActiveValue(listItem.value)}
            isActive={activeValue === listItem.value}
          >
            {listItem.label}
          </Tab>
        )
      })}
    </Field>
  )
}

const Field = styled.div`
  display: flex;
`

const Tab = styled.div<{ isActive: boolean }>`
  flex: 1 1 100%;

  display: flex;
  height: 40px;
  min-width: 56px;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 11px 15px;
  box-shadow: 0 0 0 1px ${props => props.theme.gray400} inset;
  color: ${props => props.theme.blue100};
  font-size: 14px;
  line-height: 18px;
  transition: 0.15s;
  cursor: pointer;
  z-index: 1;
  position: relative;

  &:not(&:first-child) {
    margin-left: -1px;
  }

  &:hover {
    background: rgba(0, 97, 237, 0.05);
  }

  ${props =>
    props.isActive &&
    css`
      background: ${props.theme.main};
      color: ${props.theme.white};
      box-shadow: 0 0 0 1px ${props.theme.main} inset;
      z-index: 2;
      pointer-events: none;

      &:hover {
        background: ${props.theme.main};
      }
    `}
`

export default CabinetTabs
