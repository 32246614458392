import React, { ReactNode } from 'react'

import { ReactComponent as Hatchback } from '@/assets/svg/car/body/hatchback.svg'
import { ReactComponent as Sedan } from '@/assets/svg/car/body/sedan.svg'
import { ReactComponent as Wagon } from '@/assets/svg/car/body/wagon.svg'
import { ReactComponent as Coupe } from '@/assets/svg/car/body/coupe.svg'
import { ReactComponent as Minivan } from '@/assets/svg/car/body/minivan.svg'
import { ReactComponent as Van } from '@/assets/svg/car/body/van.svg'
import { ReactComponent as Pickup } from '@/assets/svg/car/body/pickup.svg'
import { ReactComponent as Suv } from '@/assets/svg/car/body/suv.svg'
import { ReactComponent as Crossover } from '@/assets/svg/car/body/crossover.svg'
import { ReactComponent as Minibus } from '@/assets/svg/car/body/minibus.svg'
import { ReactComponent as Liftback } from '@/assets/svg/car/body/liftback.svg'
import { ReactComponent as Chassis } from '@/assets/svg/car/body/chassis.svg'
import { ReactComponent as Cabriolet } from '@/assets/svg/car/body/cabriolet.svg'

export const bodyIconMap: Record<string, ReactNode> = {
  Хэтчбек: <Hatchback />,
  Седан: <Sedan />,
  Универсал: <Wagon />,
  Купе: <Coupe />,
  Минивэн: <Minivan />,
  Фургон: <Van />,
  Пикап: <Pickup />,
  Внедорожник: <Suv />,
  Кроссовер: <Crossover />,
  Микроавтобус: <Minibus />,
  Лифтбек: <Liftback />,
  Шасси: <Chassis />,
  Автобус: <Minibus />,
  Кабриолет: <Cabriolet />,
}
