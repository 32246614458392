import React from 'react'
import styled from '@emotion/styled'

interface Props {
  children?: React.ReactNode
}

function UnorderedLists({ children }: Props) {
  return <List>{children}</List>
}

export default UnorderedLists

const List = styled.ul``
