import React from 'react'
import styled from '@emotion/styled'

import { CarsMenuProps } from './types'

import { bodyIconMap } from '@/constants/icons'
import MenuItem from '@/components/Layout/components/Header/components/Menu/MenuMobile/components/MenuItem'
import { HeaderVariables } from '@/components/Layout/components/Header/components/Menu/constants'
import MenuColumn from '@/components/Layout/components/Header/components/MenuColumn'

function CarsMenu({ menuItem }: CarsMenuProps) {
  return (
    <MenuItem
      label={menuItem.label.replace(HeaderVariables.Cars, '').trim()}
      link={menuItem.link ?? ''}
      isNewTab={menuItem.isNewTab}
    >
      {menuItem.children.map((item, index) => (
        <MenuColumnWrapper key={index}>
          <MenuColumn
            menu={item.children.map(item => ({
              title: item.label,
              link: item.link ?? '',
              isNewTab: item.isNewTab,
              icon: bodyIconMap[item.label],
            }))}
          />
        </MenuColumnWrapper>
      ))}
    </MenuItem>
  )
}

export default CarsMenu

const MenuColumnWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid ${props => props.theme.gray200};
  }
`
