import { OptionType } from '@/typings/common'

export const locationData: OptionType[] = [
  { label: 'Брест', value: 'brest' },
  { label: 'Витебск', value: 'vitebsk' },
  { label: 'Гомель', value: 'gomel' },
  { label: 'Гродно', value: 'hrodno' },
  { label: 'Минск', value: 'minsk' },
  { label: 'Могилев', value: 'mogilev' },
]

export const locationWithCaseData: OptionType[] = [
  { label: 'Бресте', value: 'brest' },
  { label: 'Витебске', value: 'vitebsk' },
  { label: 'Гомеле', value: 'gomel' },
  { label: 'Гродно', value: 'hrodno' },
  { label: 'Минске', value: 'minsk' },
  { label: 'Могилеве', value: 'mogilev' },
]
