import React from 'react'
import moment from 'moment'
import styled from '@emotion/styled'

import { UserBonusesType } from '@/services/users/typings'

interface Props {
  bonuses: UserBonusesType
}

function Cashback({ bonuses }: Props) {
  const { status, percent, dateTo } = bonuses.cashback

  return (
    <Block>
      {status || percent ? (
        <Row>
          {status && (
            <Text>
              Стaтус: <span>{status}</span>
            </Text>
          )}
          {percent && <Text>Кешбэк: {percent}%</Text>}
        </Row>
      ) : null}
      {dateTo && (
        <Row>
          <Text>
            Статус действует до{' '}
            {moment(dateTo, 'YYYY-MM-DD').format('DD.MM.YYYY')}
          </Text>
        </Row>
      )}
    </Block>
  )
}

const Block = styled.div`
  margin-top: 10px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3px;

  &:first-child {
    margin-top: 0;
  }
`

const Text = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.white};

  &:first-child {
    padding-right: 15px;
  }

  span {
    font-weight: 700;
  }
`

export default Cashback
