import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'

import { media } from '@/utils/mixin'

const ACTION_LIST: Array<{ label: string; url: string }> = [
  { label: 'Бонусная программа', url: '/bonus-program/' },
]

function Actions() {
  return (
    <Component>
      <Nav>
        <NavList>
          {ACTION_LIST.map((action, index) => (
            <NavItem key={index}>
              <NavLink to={action.url}>{action.label}</NavLink>
            </NavItem>
          ))}
        </NavList>
      </Nav>
    </Component>
  )
}

export default Actions

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  width: 50%;
  padding: 0 15px;
  margin-bottom: 30px;

  ${media.tablet(css`
    width: 100%;
    padding: 0;
  `)}
`

const Nav = styled.nav``

const NavList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-right: -18px;
`

const NavItem = styled.li`
  &:not(:last-child) {
    margin-right: 18px;
  }
`

const NavLink = styled(Link)`
  display: block;
  line-height: 20px;
  color: ${props => props.theme.gray500};
  text-transform: uppercase;
  border-bottom: 1px solid transparent;
  transition:
    color 0.15s,
    border-bottom 0.15s;

  &:hover {
    color: ${props => props.theme.gray500};
    text-decoration: none;
    border-bottom: 1px solid ${props => props.theme.gray500};
  }
`
