import React, { PropsWithChildren, useEffect, useState } from 'react'

import { DealersContext } from '@/providers/Dealers/DealersContext'
import { IDealerStore } from '@/providers/Dealers/types'
import { FETCH_STATUSES } from '@/typings/common'
import { getDealers } from '@/services/requests'

export const DealersProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [dealersData, setDealersData] = useState<IDealerStore>({
    status: FETCH_STATUSES.IDLE,
    value: [],
  })

  const fetchDealersData = async () => {
    setDealersData(prev => ({ ...prev, status: FETCH_STATUSES.LOADING }))
    try {
      const response = await getDealers()
      setDealersData({ status: FETCH_STATUSES.SUCCESS, value: response.data })
    } catch (error) {
      setDealersData(prev => ({ ...prev, status: FETCH_STATUSES.FAILURE }))
      return Promise.reject(error)
    }
  }

  useEffect(() => {
    fetchDealersData()
  }, [])

  return (
    <DealersContext.Provider value={{ state: dealersData }}>
      {children}
    </DealersContext.Provider>
  )
}

export default DealersProvider
