import React from 'react'
import styled from '@emotion/styled/macro'

import { ServiceDropdownProps } from './types'

import {
  headerServices,
  HeaderVariables,
} from '@/components/Layout/components/Header/components/Menu/constants'
import Dropdown from '@/components/Layout/components/Header/components/Dropdown'
import MenuColumn from '@/components/Layout/components/Header/components/MenuColumn'
import InfoLink from '@/components/Layout/components/Header/components/InfoLink'

function ServiceDropdown({ menuItem }: ServiceDropdownProps) {
  const hasHeaderServices = menuItem.children.some(item =>
    item.label.includes(HeaderVariables.Services),
  )
  const serviceOnline = menuItem.children.find(item =>
    item.label.includes(HeaderVariables.ServiceOnline),
  )

  return (
    <Dropdown
      label={menuItem.label.replace(HeaderVariables.Service, '').trim()}
      link={menuItem.link ?? ''}
      isNewTab={menuItem.isNewTab}
    >
      {hasHeaderServices && (
        <Top>
          {headerServices.map(({ section, items }, index) => (
            <MenuColumn
              key={index}
              title={section ?? ''}
              menu={
                items && items.length !== 0
                  ? items.map(({ image, name, link, isNewTab }) => ({
                      image,
                      title: name ?? '',
                      link: link ?? '',
                      isNewTab,
                    }))
                  : []
              }
              columns={index === 0 ? 2 : 1}
            />
          ))}
        </Top>
      )}

      {serviceOnline && (
        <Bottom>
          <InfoLink
            label={serviceOnline.label
              .replace(HeaderVariables.ServiceOnline, '')
              .trim()}
            link={serviceOnline.link ?? ''}
            info={'Онлайн-запись на сервис происходит в личном кабинете'}
            isNewTab={serviceOnline.isNewTab}
          />
        </Bottom>
      )}
    </Dropdown>
  )
}

export default ServiceDropdown

const Top = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr;
  gap: 20px;
`

const Bottom = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${props => props.theme.gray900};
`
