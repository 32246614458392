import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import CarTracks from './components/CarTracks'

import { media } from '@/utils/mixin'
import { useProfile } from '@/providers/Profile'

function ProgressBar() {
  const { profile } = useProfile()

  if (!profile || !profile.data) return null

  return (
    <Block>
      <Inner>
        <Left>
          <Title>Дойди до финиша!</Title>
          <Subtitle>Заполните профиль</Subtitle>
          <Description>
            Ваш профиль заполнен на{' '}
            <Percent>{profile?.data?.profilePercentFullness ?? 0}%</Percent>
          </Description>
        </Left>
        <Right>
          <RightTitle> — Давай, вперед! </RightTitle>
          <CarTracks percent={profile?.data?.profilePercentFullness ?? 0} />
        </Right>
      </Inner>
    </Block>
  )
}

const Block = styled.div`
  background-color: ${props => props.theme.white};
  position: relative;
  padding: 30px;
  margin: 30px 0;

  ${media.mobile(css`
    padding: 20px;
    margin: 60px 0;
  `)}
`

const Inner = styled.div`
  display: flex;

  ${media.tabletSmall(css`
    display: block;
  `)}
`

const Left = styled.div`
  width: 30%;
  margin-right: 10%;

  ${media.tabletSmall(css`
    width: 100%;
    margin-right: 0;
  `)}
`

const Right = styled.div`
  flex: 1 1 auto;

  ${media.tabletSmall(css`
    margin-top: 30px;
  `)}
`

const Title = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.main};

  ${media.mobile(css`
    font-size: 20px;
    line-height: 28px;
  `)}
`

const Subtitle = styled.h3`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.black100};

  ${media.mobile(css`
    font-size: 20px;
    line-height: 28px;
  `)}
`

const Description = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
  color: ${props => props.theme.blue100};

  ${media.mobile(css`
    margin-top: 10px;
  `)}
`

const Percent = styled.span`
  color: #f95353;
`

const RightTitle = styled.p`
  font-weight: 500;
  display: inline-block;
  max-width: 175px;
  font-style: italic;
  color: ${props => props.theme.gray500};
  font-size: 14px;
  padding: 4px 0 59px;
`

export default ProgressBar
