import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'

import { DropdownProps } from './types'

import { useHover } from '@/hooks/useHover'

function Dropdown({ label, link, isNewTab, children }: DropdownProps) {
  const { isHovering, getHoverProps } = useHover({
    mouseLeaveDelay: 300,
  })

  return (
    <Component {...getHoverProps()}>
      {link ? (
        <NavLink
          to={link}
          target={isNewTab ? '_blank' : '_self'}
          isOpen={isHovering}
        >
          {label}
        </NavLink>
      ) : (
        <NavButton isOpen={isHovering}>{label}</NavButton>
      )}
      <DropdownMenu isOpen={isHovering}>
        <DropdownMenuContainer>{children}</DropdownMenuContainer>
      </DropdownMenu>
    </Component>
  )
}

export default Dropdown

const Component = styled.div``

const NavStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 21px 25px;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.15s;
  white-space: nowrap;
  flex-wrap: nowrap;
  z-index: 91;
`

const NavLink = styled(Link)<{ isOpen?: boolean }>`
  ${NavStyles};

  color: ${props => props.theme.white};

  &:hover {
    background: ${props => props.theme.white};
    color: ${props => props.theme.main};
  }
  ${props =>
    props.isOpen &&
    css`
      background: ${props.theme.white};
      color: ${props.theme.main};
    `};
`

const NavButton = styled.button<{ isOpen?: boolean }>`
  ${NavStyles};
  color: ${props => props.theme.white};
  &:hover {
    background: ${props => props.theme.white};
    color: ${props => props.theme.main};
  }
  ${props =>
    props.isOpen &&
    css`
      background: ${props.theme.white};
      color: ${props.theme.main};
    `};
`

const DropdownMenu = styled.div<{ isOpen: boolean }>`
  display: none;

  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 201;

  padding: 40px 0 30px;
  box-shadow: ${props => props.theme.blackShadow300};
  background: ${props => props.theme.white};

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `};
`

const DropdownMenuContainer = styled.div`
  width: 100%;
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
`
