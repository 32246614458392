import { Dealer } from '@/providers/Dealers/types'

export const getDealerLinkById = (
  dealers: Dealer[],
  searchId: number,
): string => {
  const foundDealer = dealers.find(({ dealerId }) => dealerId === searchId)

  if (foundDealer && foundDealer.url) {
    return foundDealer.url
  } else {
    return '/dealers/'
  }
}
