import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'
import { payments } from '@/components/Layout/components/Footer/components/Main/components/Pay/constants'

function Pay() {
  return (
    <Component>
      <List>
        {payments.map((item, index) => {
          return (
            <Item key={index}>
              <img loading='lazy' src={item} alt={'payment-' + index} />
            </Item>
          )
        })}
      </List>
    </Component>
  )
}

export default Pay

const Component = styled.div``

const List = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -5px -10px;

  ${media.mobileUp(css`
    justify-content: flex-end;
  `)}
`

const Item = styled.span`
  margin: 5px 10px;

  picture,
  img {
    max-width: 110px;
    height: 25px;

    svg {
      fill: ${props => props.theme.white};
      opacity: 0.4;
    }
  }
`
