import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import CheckBoxTick from '@/assets/svg/checkbox-tick.svg'
import { media } from '@/utils/mixin'

interface Props {
  name: string
  text: string
  isChecked: boolean
  setChecked: (value: boolean) => void
}

function CabinetCheckbox({ name, setChecked, isChecked, text }: Props) {
  return (
    <Component>
      <Label htmlFor={name}>
        <Toggle isChecked={isChecked}>
          <CheckBoxTick />
        </Toggle>
        <Text>{text}</Text>
        <Input
          type={'checkbox'}
          id={name}
          onChange={() => setChecked(!isChecked)}
        />
      </Label>
    </Component>
  )
}

const Component = styled.div``

const Label = styled.label`
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.blue100};

  display: flex;
  align-items: center;
  cursor: pointer;

  ${media.tabletSmall(css`
    cursor: default;
  `)}
`

const Input = styled.input`
  visibility: hidden;
  width: 0;
`

const Toggle = styled.div<{ isChecked: boolean }>`
  width: 16px;
  height: 16px;
  border: 1px solid ${props => props.theme.gray400};
  background: ${props => props.theme.white};
  transition: 0.15s;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
    opacity: 0;
    visibility: hidden;
  }

  ${({ isChecked, theme }) =>
    isChecked &&
    css`
      border-color: ${theme.main};
      background: ${theme.main};

      svg {
        opacity: 1;
        visibility: visible;
      }
    `}
`

const Text = styled.div`
  padding-left: 10px;
`

export default CabinetCheckbox
