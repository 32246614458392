import { CabinetHeaderItemOption } from './CabinetHeader';

export const CABINET_HEADER: CabinetHeaderItemOption[] = [
  {
    id: 1,
    label: 'Личные данные',
    name: 'HOME',
    link: '/',
    isNewTab: false,
    children: [],
  },
  {
    id: 2,
    label: 'Мои автомобили',
    name: 'CARS',
    link: '/cars',
    isNewTab: false,
    children: [],
  },
  {
    id: 3,
    label: 'Мои бонусы',
    name: 'BONUSES',
    link: '/bonuses',
    isNewTab: false,
    children: [],
  },
  {
    id: 4,
    label: 'Мои покупки',
    name: 'PURCHASES',
    link: '/purchases',
    isNewTab: false,
    children: [],
  },
  {
    id: 5,
    label: 'Мои заявки',
    name: 'REQUESTS',
    link: '/requests',
    isNewTab: false,
    children: [],
  },
  // {
  //   id: 6,
  //   label: 'Избранное',
  //   name: 'FAVORITES',
  //   link: '/favorites',
  //   isNewTab: false,
  //   children: [],
  // },
  {
    id: 7,
    label: 'Запись на сервис',
    name: 'SERVICE',
    link: '/service',
    isNewTab: false,
    children: [],
  },
];
