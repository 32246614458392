import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { ReactComponent as CallIcon } from '@/assets/svg/call.svg'
import { googleEvent } from '@/utils/events'
import { media } from '@/utils/mixin'

function MobilePhone() {
  const onPhoneClick = () => {
    googleEvent('')
  }

  return (
    <Component>
      <Link onClick={onPhoneClick} href={'tel:+375296770099'}>
        <CallIcon />
      </Link>
    </Component>
  )
}

export default MobilePhone

const Component = styled.div`
  margin: 0 5px;
`

const Link = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 40px;
  height: 40px;
  color: ${props => props.theme.white};

  ${media.mobileSmall(css`
    width: 25px;
    height: 25px;
  `)}
`
