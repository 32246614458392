import { Outlet, Navigate } from 'react-router-dom'

import Layout from '@/components/Layout'
import cookie from '@/utils/cookie'

const PrivateRoutes = () => {
  let auth = {
    token: cookie.get(
      process.env.REACT_APP_COOKIE_ACCESS_TOKEN_PARAM ?? 'accessToken',
    ),
  }
  return auth.token ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to='/' />
  )
}

export default PrivateRoutes
