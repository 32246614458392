import React from 'react'

import Cabinet from '@/modules/Cabinet'

function Purchases() {
  return (
    <Cabinet activeTab={'PURCHASES'}>PURCHASES</Cabinet>
  )
}

export default Purchases

