import { MenuItemType, Nullable, ThumbnailType } from '@/typings/common'

export const headerMenu: Array<MenuItemType> = [
  {
    id: 4249,
    label: 'Авто в наличии :CARS:',
    link: '/cars',
    isNewTab: false,
    children: [
      {
        id: 4250,
        label: 'Авто в наличии:',
        link: null,
        isNewTab: false,
        children: [
          {
            id: 4251,
            label: 'Все авто',
            link: '/cars',
            isNewTab: false,
            children: [],
          },
          {
            id: 4252,
            label: 'Новые авто',
            link: '/cars/new',
            isNewTab: false,
            children: [],
          },
          {
            id: 4253,
            label: 'Авто с пробегом',
            link: '/cars/amp',
            isNewTab: false,
            children: [],
          },
          {
            id: 4254,
            label: 'Электромобили',
            link: '/cars/electro',
            isNewTab: false,
            children: [],
          },
          {
            id: 4255,
            label: 'Коммерческие',
            link: '/cars/commercial',
            isNewTab: false,
            children: [],
          },
          {
            id: 4256,
            label: 'Выкуп авто',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4257,
            label: 'Спецпредложения',
            link: '/',
            isNewTab: false,
            children: [],
          },
        ],
      },
      {
        id: 4258,
        label: 'По типу кузова:',
        link: null,
        isNewTab: false,
        children: [
          {
            id: 4259,
            label: 'Седан',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4260,
            label: 'Кроссовер',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4261,
            label: 'Хэтчбек',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4262,
            label: 'Универсал',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4263,
            label: 'Внедорожник',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4264,
            label: 'Пикап',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4265,
            label: 'Микроавтобус',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4266,
            label: 'Фургон',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4267,
            label: 'Лифтбек',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4268,
            label: 'Минивэн',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4269,
            label: 'Шасси',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4270,
            label: 'Автобус',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4271,
            label: 'Кабриолет',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4272,
            label: 'Купе',
            link: '/',
            isNewTab: false,
            children: [],
          },
        ],
      },
      {
        id: 4273,
        label: 'По цене:',
        link: null,
        isNewTab: false,
        children: [
          {
            id: 4274,
            label: 'от 10 000 BYN до 15 000 BYN',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4275,
            label: 'от 15 000 BYN до 20 000 BYN',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4276,
            label: 'от 20 000 BYN до 25 000 BYN',
            link: '/',
            isNewTab: false,
            children: [],
          },
          {
            id: 4277,
            label: 'от 20 000 BYN и выше',
            link: '/',
            isNewTab: false,
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 4278,
    label: 'Электромобили',
    link: '/electro',
    isNewTab: false,
    children: [],
  },
  {
    id: 4279,
    label: 'Модельный ряд',
    link: '/catalog/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4284,
    label: 'Сервис :SERVICE:',
    link: '/service',
    isNewTab: false,
    children: [
      {
        id: 4285,
        label: ':SERVICES:',
        link: null,
        isNewTab: false,
        children: [],
      },
      {
        id: 4286,
        label: 'Онлайн запись на сервис :SERVICE_ONLINE:',
        link: '/personal/login?redirect_uri=/personal/service',
        isNewTab: false,
        children: [],
      },
    ],
  },
  {
    id: 4287,
    label: 'Оценка и выкуп',
    link: '/value-car/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4288,
    label: 'Акции',
    link: '/offers/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4289,
    label: 'Новости',
    link: '/news/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4290,
    label: 'Обзоры',
    link: '/reviews',
    isNewTab: false,
    children: [],
  },
  {
    id: 4291,
    label: 'Как купить',
    link: '/how-to/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4292,
    label: 'Дилеры',
    link: '/dealers/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4293,
    label: 'Страхование',
    link: '/finservices/insurance/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4294,
    label: 'Бонусная программа',
    link: '/bonus-program/',
    isNewTab: false,
    children: [],
  },
  {
    id: 4295,
    label: 'Аварийный менеджер',
    link: '/caraccidenthelp',
    isNewTab: false,
    children: [],
  },
  {
    id: 4296,
    label: 'Мобильное приложение',
    link: 'https://atlantm.by/application',
    isNewTab: true,
    children: [],
  },
]

export const headerMenuMobile: Array<MenuItemType> = [
  {
    id: 2375,
    label: 'Связаться с нами',
    link: '/dealers/',
    isNewTab: false,
    children: [
      {
        id: 2376,
        label: ':PHONE:',
        link: null,
        isNewTab: false,
        children: [],
      },
      {
        id: 2377,
        label: 'online@atlantm-m.by',
        link: 'mailto:online@atlantm-m.by',
        isNewTab: false,
        children: [],
      },
      {
        id: 2378,
        label: 'Заказать звонок',
        link: ':CALL:',
        isNewTab: false,
        children: [],
      },
      {
        id: 2379,
        label: 'Книга отзывов',
        link: 'https://ekov2.atlantm.com/ru/',
        isNewTab: true,
        children: [],
      },
      {
        id: 2380,
        label: 'Пользовательское соглашение',
        link: '/license-agreement/',
        isNewTab: false,
        children: [],
      },
    ],
  },
]

export enum HeaderVariables {
  Brands = ':BRANDS:',
  Banner = ':BANNER:',
  Phone = ':PHONE:',
  Call = ':CALL:',
  Models = ':MODELS:',
  Cars = ':CARS:',
  Service = ':SERVICE:',
  Services = ':SERVICES:',
  ServiceOnline = ':SERVICE_ONLINE:',
}

export const headerServices: Array<{
  section: Nullable<string>
  items: Array<{
    image: Nullable<ThumbnailType>
    name: Nullable<string>
    link: Nullable<string>
    isNewTab: boolean
  }>
}> = [
  {
    section: 'Популярные услуги',
    items: [
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/fy/zg/fyZGsT0KUP.png',
        },
        name: 'Кузовной ремонт',
        link: '/',
        isNewTab: false,
      },
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/ih/9t/Ih9tnLmq7H.png',
        },
        name: 'Техническое обслуживание',
        link: '/',
        isNewTab: false,
      },
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/f4/xl/F4XLVZdmeQ.png',
        },
        name: 'Замена масла в двигателе',
        link: '/',
        isNewTab: false,
      },
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/bi/rd/bIrDfqvCKf.png',
        },
        name: 'Установка дополнительного оборудования',
        link: '/',
        isNewTab: false,
      },
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/bq/ny/BqNYWwCh7g.png',
        },
        name: 'Регулировка развал-схождение',
        link: '/',
        isNewTab: false,
      },
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/xx/ac/xXAcXi8z7s.png',
        },
        name: 'Шиномонтаж',
        link: '/',
        isNewTab: false,
      },
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/tv/jv/tvJvty34Bx.png',
        },
        name: 'Ремонт автоэлектрики',
        link: '/',
        isNewTab: false,
      },
      {
        image: {
          url: 'https://dev.atlantm.by/uploads/tmp/6q/xz/6qXZSAESV8.png',
        },
        name: 'Компьютерная диагностика двигателя',
        link: '/',
        isNewTab: false,
      },
    ],
  },
  {
    section: 'Продукты',
    items: [
      {
        image: null,
        name: 'Продлённая гарантия',
        link: '/',
        isNewTab: false,
      },
      {
        image: null,
        name: 'Сервисные пакеты',
        link: '/',
        isNewTab: false,
      },
      {
        image: null,
        name: 'Шинный отель',
        link: '/',
        isNewTab: false,
      },
      {
        image: null,
        name: 'Аварийный менеджер',
        link: '/',
        isNewTab: false,
      },
    ],
  },
  {
    section: 'Другие продукты',
    items: [
      {
        image: null,
        name: 'Бонусная программа',
        link: '/',
        isNewTab: false,
      },
      {
        image: null,
        name: 'Мобильное приложение',
        link: '/',
        isNewTab: false,
      },
      {
        image: null,
        name: 'Личный кабинет',
        link: '/',
        isNewTab: false,
      },
      {
        image: null,
        name: 'Дилеры',
        link: '/',
        isNewTab: false,
      },
    ],
  },
]
