import React from 'react'
import styled from '@emotion/styled'

import Main from '@/components/Layout/components/Footer/components/Main'
import Bottom from '@/components/Layout/components/Footer/components/Bottom'

function Footer() {
  return (
    <FooterContainer id='footer'>
      <Main />
      <Bottom />
    </FooterContainer>
  )
}

export default Footer

const FooterContainer = styled.footer``
