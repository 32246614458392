import React, { PropsWithChildren } from 'react'

import { CabinetCarsContext } from './CabinetCarsContext'

export const CabinetCarsProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [showAll, setShowAll] = React.useState(false)
  const [searchQuery, setSearchQuery] = React.useState('')
  const [hiddenList, setHiddenList] = React.useState<number[]>([])

  const updateShowAll = React.useCallback((value: boolean) => {
    setShowAll(value)
  }, [])

  const updateSearchQuery = React.useCallback((value: string) => {
    setSearchQuery(value)
  }, [])

  const updateHiddenList = React.useCallback((value: number[]) => {
    setHiddenList(value)
  }, [])

  return (
    <CabinetCarsContext.Provider
      value={{
        showAll,
        updateShowAll,
        searchQuery,
        updateSearchQuery,
        hiddenList,
        updateHiddenList,
      }}
    >
      {children}
    </CabinetCarsContext.Provider>
  )
}
