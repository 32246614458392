import { Dealer } from '@/providers/Dealers/types'
import { ApiService } from '@/services/api'

export const api = new ApiService({ url: process.env.REACT_APP_API_URL ?? '' })

export const request = api.getRequest()

export function getDealers(): Promise<{ data: Dealer[] }> {
  return request.get({ path: `/dealers` })
}
