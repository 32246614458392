import React from 'react'
import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'

import { MenuColumnProps } from './types'

function MenuColumn({ title, menu, columns = 1 }: MenuColumnProps) {
  return (
    <Component>
      {title && <Title>{title}</Title>}

      <Body columns={columns}>
        {menu.map((item, index) => (
          <MenuItem key={index}>
            <MenuLink
              to={item.link}
              target={item.isNewTab ? '_blank' : '_self'}
            >
              {item.icon ? <Icon>{item.icon}</Icon> : null}

              <MenuTitle>{item.title}</MenuTitle>
            </MenuLink>
          </MenuItem>
        ))}
      </Body>
    </Component>
  )
}

export default MenuColumn

const Component = styled.div``

const Title = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
  color: ${props => props.theme.blue200};
`

const Body = styled.ul<{ columns: number }>`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};
  gap: 20px;
`

const MenuItem = styled.li`
  display: flex;
`

const Icon = styled.div`
  margin-right: 10px;

  svg {
    width: 40px;
    height: 22px;

    path {
      fill: ${props => props.theme.gray1900};
    }

    circle {
      stroke: ${props => props.theme.gray1900};
    }

    path,
    circle {
      transition: 0.3s;
    }
  }
`

const MenuTitle = styled.p`
  font-size: 14px;
  line-height: 16px;
  border-bottom: 1px solid transparent;
  transition: 0.3s;
  color: ${props => props.theme.black};
`

const MenuLink = styled(Link)`
  display: flex;
  align-items: center;

  &:hover {
    ${Icon} {
      svg {
        path {
          fill: ${props => props.theme.main};
        }

        circle {
          stroke: ${props => props.theme.main};
        }
      }
    }

    ${MenuTitle} {
      color: ${props => props.theme.main};
      border-color: ${props => props.theme.main};
    }
  }
`
