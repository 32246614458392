import React from 'react'

import Cabinet from '@/modules/Cabinet'

function Service() {
  return (
    <Cabinet activeTab={'SERVICE'}>SERVICE</Cabinet>
  )
}

export default Service
