import React from 'react'
import styled from '@emotion/styled'

import appstoreIcon from '@/assets/svg/apps/appstore.svg'
import googlePlayIcon from '@/assets/svg/apps/gplay.svg'

function Apps() {
  return (
    <Component>
      <Link href={'https://app.appsflyer.com/app/5e28296d'} target='_blank'>
        <img loading='lazy' src={appstoreIcon} alt='' />
      </Link>

      <Link href={'https://app.appsflyer.com/app/c8397647'} target='_blank'>
        <img loading='lazy' src={googlePlayIcon} alt='' />
      </Link>
    </Component>
  )
}

export default Apps

const Component = styled.div`
  margin-top: 35px;
`

const Link = styled.a`
  display: block;
  width: 132px;
  height: 40px;

  &:not(:last-child) {
    margin-bottom: 10px;
  }
`
