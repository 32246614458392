import React from 'react'
import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'

import { media } from '@/utils/mixin'
import { ReactComponent as AtlantmLogo } from '@/assets/svg/atlantm-secondary-logo.svg'
import { ReactComponent as CloseIcon } from '@/assets/svg/close-24dp.svg'
import { ContentContainerSmall } from '@/components/common'

function Header() {
  const url = process.env.REACT_APP_ORIGIN
  function handleCloseLoginPage() {
    if (url) window.location.href = url
  }

  return (
    <Component>
      <Container>
        <Content>
          <LogoLink to={url ?? ''}>
            <AtlantmLogo />
          </LogoLink>
          <CloseButton onClick={handleCloseLoginPage} type='button'>
            <CloseIcon />
          </CloseButton>
        </Content>
      </Container>
    </Component>
  )
}

export default Header

const Component = styled.header`
  position: relative;
  height: 80px;
  border-bottom: solid 1px ${props => props.theme.gray600};

  ${media.tablet(css`
    height: 60px;
  `)}
`

const Container = styled(ContentContainerSmall)`
  height: 100% !important;
  display: flex;
  align-items: center;

  ${media.tablet(css`
    padding: 0 20px;
  `)}
`

const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const LogoLink = styled(Link)`
  svg {
    width: 140px;
    height: 35px;
  }
`

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid ${props => props.theme.main};
  border-radius: 50%;
  transition: 0.3s;

  svg {
    transition: 0.3s;
    fill: ${props => props.theme.black};
  }

  &:hover {
    border-color: ${props => props.theme.main};
    background: ${props => props.theme.main};

    svg {
      fill: ${props => props.theme.white};
    }
  }
`
