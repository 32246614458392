import React from 'react'

import Checkbox from '@/components/Checkbox'

type Props = {
  checked?: boolean
  onChange?: (value: boolean) => void
  className?: string
  disabled?: boolean
}

const CheckboxAgreement = ({
  checked,
  onChange,
  className,
  disabled,
}: Props) => {
  const content =
    '<p>Я подтверждаю своё ознакомление с <a href="https://atlantm.by/usloviya-obrabotki-personalnyh-dannyh" target="_blank">Условиями обработки персональных данных</a> и выражаю согласие на обработку персональных данных</p>'

  return (
    <Checkbox
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      label={content || 'Empty'}
      className={className}
    />
  )
}

export default CheckboxAgreement
