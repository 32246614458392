import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'
import { ReactComponent as NoCarsIcon } from '@/assets/svg/no-car.svg'
import CabinetSwitch from '@/components/PersonalCabinetForms/components/CabinetSwitch'
import { useCabinetCars } from '@/providers/CabinetCars/useCabinetCars'

function NoActiveCars() {
  const { showAll, updateShowAll } = useCabinetCars()

  return (
    <Component>
      <Image>
        <NoCarsIcon />
      </Image>
      <Title>В этом разделе нет активных автомобилей</Title>
      <Text>
        Возможно, Вы скрыли все автомобили и сейчас они находятся в архиве.
        <br />
        Чтобы проверить это воспользуйтесь переключателем "Показать скрытые
        авто".
      </Text>
      <SwitchWrapper>
        <CabinetSwitch
          name={`showAll`}
          text={'Показать скрытые авто'}
          isChecked={showAll}
          setChecked={updateShowAll}
        />
      </SwitchWrapper>
    </Component>
  )
}

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 40px;

  ${media.mobile(css`
    margin-top: 20px;
  `)}
`

const Image = styled.div`
  margin-bottom: 40px;

  svg {
    width: 141px;
    height: 141px;
  }

  ${media.tabletSmall(css`
    margin-bottom: 20px;
  `)}
`

const Title = styled.h2`
  color: ${props => props.theme.black100};
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 10px;
  text-align: center;

  ${media.tabletSmall(css`
    font-size: 20px;
    line-height: 30px;
  `)}
`

const Text = styled.p`
  color: ${props => props.theme.black100};
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 40px;
  text-align: center;
`

const SwitchWrapper = styled.div``

export default NoActiveCars
