import { css } from '@emotion/react'

export type MediaQueryParams = {
  min?: number
  max?: number
}

export type CssSnippet = ReturnType<typeof css>

export function createMediaQuery({ min, max }: MediaQueryParams): string {
  if (!min && !max && process.env.NODE_ENV === 'development') {
    throw new Error('You didn\'t specify "min" or "max" width for @media mixin')
  }

  /**
   * Note that since browsers do not currently support range context queries,
   * we work around the limitations of min- and max- prefixes and viewports with fractional widths
   * (which can occur under certain conditions on high-dpi devices, for instance)
   * by using values with higher precision for these comparisons (e.g. 767.98px).
   *
   * Reference: "Bootstrap: Responsive breakpoints"
   * https://getbootstrap.com/docs/4.4/layout/overview/#responsive-breakpoints
   *
   * Reference: "Using “min-” and “max-” Prefixes On Range Features"
   * https://www.w3.org/TR/mediaqueries-4/#mq-min-max
   */
  return min && max
    ? `(min-width: ${min}px) and (max-width: ${max - 0.02}px)`
    : min
      ? `(min-width: ${min}px)`
      : max
        ? `(max-width: ${max - 0.02}px)`
        : ''
}

export function createMediaMixin(
  params: MediaQueryParams,
): (snippet: CssSnippet) => CssSnippet {
  const mediaQuery = createMediaQuery(params)

  return styles => css`
    @media ${mediaQuery} {
      ${styles};
    }
  `
}

export const breakpoints = {
  /** iPhone 5/SE */
  mobileSmall: 320,
  /** iPhone 6/7/8/X */
  mobileMedium: 375,
  /** iPhone 6/7/8 Plus */
  mobileLarge: 414,
  /** iPad 1, 2, Mini and Air */
  tabletSmall: 768,
  tabletLarge: 1024,
  /** 1280 - 16 = 1264 -> 1260 - more beautiful number :) */
  laptop: 1260,
  /** 1536 - 16 = 1520 -> 1500 - more beautiful number :) */
  desktop: 1540,
}

/**
 * Reference: https://get.foundation/sites/docs/media-queries.html#working-with-media-queries
 *
 * Media queries name convention: {breakpoint + modifier}
 * Modifiers: "up" | "only" | "down" (default)
 * "down" is default modifier, because we use desktop-first approach
 *
 * Examples:
 *
 * "tablet", "tabletDown" - tablet or smaller
 * "tabletOnly" - only tablet
 * "tabletUp" - tablet or larger
 */
export const media = {
  mobileSmall: createMediaMixin({
    max: breakpoints.mobileMedium,
  }),
  mobileMedium: createMediaMixin({
    max: breakpoints.mobileLarge,
  }),
  mobileMediumOnly: createMediaMixin({
    min: breakpoints.mobileSmall,
    max: breakpoints.mobileLarge,
  }),
  mobileLarge: createMediaMixin({
    max: breakpoints.tabletSmall,
  }),
  mobileLargeOnly: createMediaMixin({
    min: breakpoints.mobileLarge,
    max: breakpoints.tabletSmall,
  }),
  mobile: createMediaMixin({
    max: breakpoints.tabletSmall,
  }),
  mobileUp: createMediaMixin({
    min: breakpoints.tabletSmall,
  }),
  tabletSmall: createMediaMixin({
    max: breakpoints.tabletLarge,
  }),
  tabletSmallUp: createMediaMixin({
    min: breakpoints.tabletLarge,
  }),
  tabletSmallOnly: createMediaMixin({
    min: breakpoints.tabletSmall,
    max: breakpoints.tabletLarge,
  }),
  tabletLarge: createMediaMixin({
    max: breakpoints.laptop,
  }),
  tabletLargeOnly: createMediaMixin({
    min: breakpoints.tabletLarge,
    max: breakpoints.laptop,
  }),
  tablet: createMediaMixin({
    max: breakpoints.laptop,
  }),
  tabletOnly: createMediaMixin({
    min: breakpoints.tabletSmall,
    max: breakpoints.laptop,
  }),
  tabletUp: createMediaMixin({
    min: breakpoints.laptop,
  }),
  laptop: createMediaMixin({
    max: breakpoints.desktop,
  }),
  laptopOnly: createMediaMixin({
    min: breakpoints.laptop,
    max: breakpoints.desktop,
  }),
  desktopOnly: createMediaMixin({
    min: breakpoints.desktop,
  }),
}
