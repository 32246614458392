import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

interface Props {
  mouseLeaveDelay?: number
}

export function useHover({ mouseLeaveDelay = 0 }: Props): {
  isHovering: boolean
  setIsHovering: Dispatch<SetStateAction<boolean>>
  getHoverProps: () => { onMouseEnter: () => void; onMouseLeave: () => void }
} {
  const location = useLocation()
  const [isHovering, setIsHovering] = useState<boolean>(false)
  const mouseLeaveTimerId = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    setIsHovering(false)

    return () => setIsHovering(false)
  }, [location])

  function onMouseEnter(): void {
    if (mouseLeaveTimerId.current) {
      clearTimeout(mouseLeaveTimerId.current)
    }
    setIsHovering(true)
  }

  function onMouseLeave(): void {
    mouseLeaveTimerId.current = setTimeout(() => {
      setIsHovering(false)
    }, mouseLeaveDelay)
  }

  function getHoverProps(): {
    onMouseEnter: () => void
    onMouseLeave: () => void
  } {
    return {
      onMouseEnter,
      onMouseLeave,
    }
  }

  return { isHovering, setIsHovering, getHoverProps }
}
