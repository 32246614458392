import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'
import {
  headerMenu,
  HeaderVariables,
} from '@/components/Layout/components/Header/components/Menu/constants'
import CarsMenu from '@/components/Layout/components/Header/components/Menu/MenuMobile/components/CarsMenu'
import ServiceMenu from '@/components/Layout/components/Header/components/Menu/MenuMobile/components/ServiceMenu'
import MenuItem from '@/components/Layout/components/Header/components/Menu/MenuMobile/components/MenuItem'
import Info from '@/components/Layout/components/Header/components/Menu/MenuMobile/components/Info'

function MenuMobile() {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isOpen])

  return (
    <Component>
      <Button type='button' onClick={() => setIsOpen(prev => !prev)}>
        <Text isActive={isOpen}>Меню</Text>
        <Line1 isActive={isOpen} />
        <Line2 isActive={isOpen} />
        <Line3 isActive={isOpen} />
      </Button>

      <Menu isOpen={isOpen}>
        <MenuList>
          {headerMenu.map((menuItem, index) => {
            const isCarsMenu = menuItem.label.includes(HeaderVariables.Cars)
            const isServiceDropdown = menuItem.label.includes(
              HeaderVariables.Service,
            )

            if (isCarsMenu) {
              return <CarsMenu key={index} menuItem={menuItem} />
            }

            if (isServiceDropdown) {
              return <ServiceMenu key={index} menuItem={menuItem} />
            }

            return (
              <MenuItem
                key={index}
                label={menuItem.label}
                link={menuItem.link ?? ''}
                isNewTab={menuItem.isNewTab}
              />
            )
          })}
        </MenuList>
        <Info />
      </Menu>
    </Component>
  )
}

export default MenuMobile

const Component = styled.div`
  margin-left: 5px;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  flex-direction: column;
  width: 40px;
  height: 40px;

  ${media.mobileSmall(css`
    width: 25px;
    height: 25px;
  `)}

  svg {
    width: 20px;
    height: 20px;
  }
`

const Text = styled.span<{ isActive: boolean }>`
  display: block;

  font-weight: 700;
  font-size: 8px;
  line-height: 10px;
  text-transform: uppercase;

  color: ${props => props.theme.white};

  transition: 0.15s;

  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 0;
    `}
`

const Line = styled.span`
  display: block;
  width: 26px;
  border-top: 2px solid ${props => props.theme.white};
  transform: translate3d(0, 0, 0) scaleX(1);
  transition: 0.15s;
`

const Line1 = styled(Line)<{ isActive: boolean }>`
  margin-top: 2px;

  transform: translate3d(0, 0, 0) scaleX(1);

  ${({ isActive }) =>
    isActive &&
    css`
      transform: translate3d(0, 2px, 0) rotate(45deg);
    `}
`

const Line2 = styled(Line)<{ isActive: boolean }>`
  margin-top: 4px;

  transform: translate3d(0, 0, 0) scaleX(1);

  ${({ isActive }) =>
    isActive &&
    css`
      transform: translate3d(0, -4px, 0) scaleX(0);
    `}
`

const Line3 = styled(Line)<{ isActive: boolean }>`
  width: 20px;
  margin-top: 4px;
  margin-right: 6px;

  transform: translate3d(0, 0, 0) scaleX(1);

  ${({ isActive }) =>
    isActive &&
    css`
      width: 26px;
      margin-right: 0;
      transform: translate3d(0, -10px, 0) rotate(-45deg);
    `}
`

const Menu = styled.nav<{ isOpen: boolean }>`
  display: none;
  -webkit-transform: translateZ(0px);
  position: fixed;

  top: 80px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 500;

  overflow: scroll;

  width: 100%;
  height: calc(var(--vh, 1vh) * 100 - 60px);

  background: ${props => props.theme.white};

  ${media.mobile(css`
    top: 60px;
  `)}

  ${({ isOpen }) =>
    isOpen &&
    css`
      display: block;
    `};
`

const MenuList = styled.ul``
