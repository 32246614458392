import { QueryParams } from './types'

export function convertParamsToString(
  params: QueryParams | URLSearchParams = {},
): string {
  let resultSearchParams: URLSearchParams

  if (params instanceof URLSearchParams) {
    resultSearchParams = params
  } else {
    const searchParams = new URLSearchParams()

    Object.entries(params).forEach(([key, value]) =>
      searchParams.append(key, value),
    )

    resultSearchParams = searchParams
  }

  const queryString = resultSearchParams.toString()
  return queryString ? '?' + queryString : ''
}

export function isomorphicLog(message: any): void {
  if (process.env.NEXT_PUBLIC_ENV === 'production') {
    return
  }

  if (message instanceof Error) {
    console.error(message)
  } else {
    console.log(`%c ${message}`, 'color: green')
  }
}
