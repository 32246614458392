import React from 'react'
import { cloneDeep } from 'lodash'
import styled from '@emotion/styled'

import { CarsItemType } from '@/services/users/typings'
import { useCabinetCars } from '@/providers/CabinetCars/useCabinetCars'
import CarsCard from '@/modules/Cars/components/CarsList/components/СarsCard'
import NoActiveCars from '@/modules/Cars/components/CarsList/components/NoActiveCars'
import NoCars from '@/modules/Cars/components/CarsList/components/NoCars'

interface Props {
  carsList: CarsItemType[]
}

function CarsList({ carsList }: Props) {
  const { showAll, searchQuery, hiddenList, updateHiddenList } =
    useCabinetCars()

  function setDefaultHiddenList() {
    const arrayNumber: number[] = []

    carsList.forEach((car, index) => {
      if (car.hidden) {
        arrayNumber.push(index)
      }
    })

    return arrayNumber
  }

  function isCarHidden(hiddenList: number[], index: number, showAll: boolean) {
    return (
      hiddenList.findIndex(hiddenCard => hiddenCard === index) !== -1 &&
      !showAll
    )
  }

  const visibleList = React.useMemo(() => {
    let result: CarsItemType[] = cloneDeep(carsList)

    if (searchQuery) {
      result = result?.filter(car => {
        const carName = `${car.brandName?.toLowerCase()} ${car.modelName?.toLowerCase()}`
        const carNumber = car.number?.toLowerCase()
        const carVin = car.vin?.toLowerCase()
        const searchValue = searchQuery.toLowerCase()

        return (
          carName?.includes(searchValue) ||
          carVin?.includes(searchValue) ||
          (car.isOwner && carNumber?.includes(searchValue))
        )
      })
    }

    return result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carsList, searchQuery, showAll])

  React.useLayoutEffect(() => {
    updateHiddenList(setDefaultHiddenList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {visibleList && visibleList.length > 0 ? (
        <List>
          {visibleList?.map((car, index) => {
            if (isCarHidden(hiddenList, index, showAll)) {
              return null
            }

            return (
              <Cell key={car.vin}>
                <CarsCard
                  car={car}
                  index={index}
                  hiddenList={hiddenList}
                  setHiddenList={(value: number[]) => updateHiddenList(value)}
                />
              </Cell>
            )
          })}
          {hiddenList.length === visibleList.length && !showAll && (
            <NoActiveCars />
          )}
        </List>
      ) : (
        <NoCars />
      )}
    </>
  )
}

const List = styled.div``

const Cell = styled.div`
  margin: 30px 0;
`

export default CarsList
