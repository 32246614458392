import React from 'react'
import DatePicker from 'react-datepicker'

import { Field } from './CabinetDataInput.style'
import { ICabinetDataInput } from './CabinetDataInput.types'

import CabinetTextInputFormik from '@/components/PersonalCabinetForms/components/CabinetTextInput/CabinetTextInput.formik'
import { ReactComponent as DateIcon } from '@/assets/svg/date-icon-cabinet.svg'
import useMedia from '@/hooks/useMedia'
import { getCurrentDate } from '@/utils/date'

function CabinetDataInputFormik({
  inputName,
  data,
  setData,
  maxDate,
}: ICabinetDataInput) {
  const isMobile = useMedia('(max-width: 1259px)')

  return (
    <Field>
      <DatePicker
        id={inputName}
        name={inputName}
        locale='ru'
        dateFormat={['dd.MM.yyyy']}
        popperClassName='some-custom-class'
        popperPlacement='top-start'
        showYearDropdown
        scrollableYearDropdown
        maxDate={maxDate ?? getCurrentDate()}
        yearDropdownItemNumber={80}
        selected={data}
        fixedHeight
        withPortal={!isMobile}
        closeOnScroll={!isMobile}
        onChangeRaw={e => {
          e.preventDefault()
        }}
        customInput={<CabinetTextInputFormik name={inputName} />}
        onChange={date => {
          setData(date as Date | null)
        }}
      />
      <DateIcon />
    </Field>
  )
}

export default CabinetDataInputFormik
