import React from 'react'

import AddField from '../AddField'
import InformationCard from '../InformationCard'
import UnorderedLists from '../UnorderedLists'
import UnorderedListsItem from '../UnorderedLists/components/UnorderedListsItem'

import { UserProfileModel } from '@/services/users/typings'
import AddPhoneFormContainer from '@/components/PersonalCabinetForms/AddPhoneForm'
import AddEmailFormContainer from '@/components/PersonalCabinetForms/AddEmailForm'
import DeleteEmailFormContainer from '@/components/PersonalCabinetForms/DeleteEmailForm'
import DeletePhoneFormContainer from '@/components/PersonalCabinetForms/DeletePhoneForm'

const CONTACT_MODALS = {
  ADD_PHONE: {
    title: 'Добавление номера телефона',
    formComponent: AddPhoneFormContainer,
  },
  DELETE_PHONE: {
    title: 'Удаление номера телефона',
    formComponent: DeletePhoneFormContainer,
  },
  ADD_EMAIL: {
    title: 'Добавление электронной почты',
    formComponent: AddEmailFormContainer,
  },
  DELETE_EMAIL: {
    title: 'Удаление электронной почты',
    formComponent: DeleteEmailFormContainer,
  },
}

interface Props {
  profileInformation: UserProfileModel
}

function ContactDetails({ profileInformation }: Props) {
  const { emails, phones } = profileInformation

  // const openModal = useModal();

  function openContactModal(modal: any) {
    console.log(modal)
    // openModal(CabinetContactModal, modal, {
    //   components: { Overlay: StyledOverlay },
    //   scrollLockDisabled: true,
    // });
  }

  return (
    <InformationCard
      title={'Контактные данные'}
      percentList={[emails[0], phones[0]]}
      description={
        'Данные помогут оперативно связаться с вами при возникновении вопросов'
      }
    >
      {emails && emails.length > 0 && (
        <UnorderedLists>
          {emails.map((email, index) => (
            <UnorderedListsItem
              key={index}
              text={email}
              isRemovable={email.length > 1}
              deleteItem={() =>
                openContactModal({
                  title: CONTACT_MODALS.DELETE_EMAIL.title,
                  formComponent: CONTACT_MODALS.DELETE_EMAIL.formComponent,
                  contact: email,
                })
              }
            />
          ))}
        </UnorderedLists>
      )}

      <AddField
        text={'Добавить Email'}
        onClick={() => openContactModal(CONTACT_MODALS.ADD_EMAIL)}
      />

      {phones && phones.length > 0 && (
        <UnorderedLists>
          {phones.map((phone, index) => (
            <UnorderedListsItem
              key={index}
              text={phone}
              isRemovable={phones.length > 1}
              deleteItem={() =>
                openContactModal({
                  title: CONTACT_MODALS.DELETE_PHONE.title,
                  formComponent: CONTACT_MODALS.DELETE_PHONE.formComponent,
                  contact: phone,
                })
              }
            />
          ))}
        </UnorderedLists>
      )}
      <AddField
        text={'Добавить Телефон'}
        onClick={() => openContactModal(CONTACT_MODALS.ADD_PHONE)}
      />
    </InformationCard>
  )
}

export default ContactDetails
