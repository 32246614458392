import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Form, FormikProps } from 'formik'
//@ts-ignore
import { IMaskInput } from 'react-imask'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'
import { InputStyles } from '@/components/PersonalCabinetForms/components/CabinetTextInput/CabinetTextInput.style'
import CabinetFormField from '@/components/PersonalCabinetForms/components/CabinetFormField'
import Spinner from '@/components/Spinner'
import { ReactComponent as ArrowIcon } from '@/assets/svg/login/arrow-right.svg'
import { CheckboxAgreement } from '@/components/Checkbox'

export type LoginFormValues = {
  phone: string
  code: string
  firstName: string
}

type Props = FormikProps<LoginFormValues> & {
  isPhoneSend: boolean
  isNew: boolean
  isLoading: boolean
}

function LoginForm({
  isPhoneSend,
  isNew,
  isLoading,
  values,
  errors,
  handleChange,
}: Props) {
  const [userAgreement, setUserAgreement] = useState(false)
  return (
    <Form autoComplete='off' noValidate>
      <Title>Вход в личный кабинет</Title>

      <TextFieldWrapper>
        {!isPhoneSend ? (
          <>
            <CabinetFormField error={errors.phone ?? ''} label={'Телефон'}>
              <Input
                type='tel'
                id='phone'
                name='phone'
                mask={process.env.REACT_APP_PHONE_MASK}
                value={values.phone}
                onChange={handleChange}
              />
            </CabinetFormField>

            <SubmitButton
              disabled={!userAgreement || isLoading || !values.phone}
              type={'submit'}
            >
              {isLoading ? <Spinner absolute={true} /> : <ArrowIcon />}
            </SubmitButton>
          </>
        ) : !isNew ? (
          <>
            <CabinetFormField
              error={errors.code ?? ''}
              label={'Код подтверждения'}
            >
              <Input
                type='text'
                id='code'
                name='code'
                placeholder='Код из СМС-сообщения'
                mask='0000'
                value={values.code}
                onChange={handleChange}
              />
            </CabinetFormField>

            <SubmitButton disabled={isLoading || !values.code} type={'submit'}>
              {isLoading ? <Spinner absolute={true} /> : <ArrowIcon />}
            </SubmitButton>
          </>
        ) : (
          <>
            <CabinetFormField error={errors.firstName ?? ''} label={'Имя'}>
              <Input
                type='text'
                id='firstName'
                name='firstName'
                value={values.firstName}
                onChange={handleChange}
              />
            </CabinetFormField>

            <SubmitButton
              disabled={isLoading || !values.firstName}
              type={'submit'}
            >
              {isLoading ? <Spinner absolute={true} /> : <ArrowIcon />}
            </SubmitButton>
          </>
        )}
      </TextFieldWrapper>

      {!isPhoneSend && (
        <Agreement>
          <CheckboxAgreement
            checked={userAgreement}
            onChange={() => setUserAgreement(!userAgreement)}
          />
        </Agreement>
      )}
    </Form>
  )
}

export default LoginForm

const Title = styled.h1`
  font-weight: 700;
  font-size: 26px;
  line-height: 32px;
  text-transform: uppercase;
  margin: 0;
  color: ${props => props.theme.main};

  ${media.tablet(css`
    font-size: 24px;
    line-height: 32px;
  `)}
`

const TextFieldWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 32px;
`

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  background: ${props => props.theme.main};
  transition: 0.3s;

  &:hover {
    background: ${props => props.theme.main};
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;

    &:hover {
      background: ${props => props.theme.main100};
    }
  }
`

const Agreement = styled.div`
  margin-top: 15px;
`

const Input = styled(IMaskInput)`
  ${InputStyles}
`
