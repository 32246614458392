import React from 'react'
import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'

interface Props {
  text: string
  onClick?: () => void
}

function AddField({ text, onClick }: Props) {
  return (
    <Field>
      <FieldInner onClick={onClick}>
        <Plus />
        <Text>{text}</Text>
      </FieldInner>
    </Field>
  )
}

const Field = styled.div`
  margin: 12px 0 33px;

  ${media.mobile(css`
    margin: 11px 0 32px;
  `)}
`

const Plus = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  color: ${props => props.theme.white};
  background: ${props => props.theme.main};
  margin-right: 9px;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    border-top: 2px solid;
    transform: translate(-50%, -50%);
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    border-top: 2px solid;
    transform: translate(-50%, -50%) rotate(90deg);
  }

  ${media.tablet(css`
    margin-right: 9px;
  `)}
`

const Text = styled.div`
  font-size: 16px;
  color: ${props => props.theme.main};
  border-bottom: 1px solid rgba(0, 97, 237, 0);
  transition: border 0.15s;
`

const FieldInner = styled.button`
  display: flex;
  align-items: center;

  &:hover {
    ${Text} {
      border-bottom: 1px solid;
    }
  }
`

export default AddField
