import { cloneDeep } from 'lodash'

import { CabinetHeaderItemOption } from './CabinetHeader'

import { UserProfilePreviewAvailability } from '@/services/users/typings'
import { Nullable } from '@/typings/common'

export function getVisibleHeaderList(
  menu: CabinetHeaderItemOption[],
  availability: Nullable<UserProfilePreviewAvailability>,
) {
  if (!availability) {
    return menu
  }

  const {
    cars,
    requests,
    bonuses,
    purchasesInsurances,
    purchasesCars,
    purchasesOther,
    purchasesServices,
  } = availability

  let visibleMenu = cloneDeep(menu)

  if (!cars) {
    visibleMenu = visibleMenu.filter(
      menuItem => menuItem.name.toLowerCase() !== 'cars',
    )
  }

  if (!bonuses) {
    visibleMenu = visibleMenu.filter(menuItem => {
      return menuItem.name.toLowerCase() !== 'bonuses'
    })
  }

  if (!requests) {
    visibleMenu = visibleMenu.filter(
      menuItem => menuItem.name.toLowerCase() !== 'requests',
    )
  }

  if (
    !purchasesCars &&
    !purchasesInsurances &&
    !purchasesOther &&
    !purchasesServices
  ) {
    visibleMenu = visibleMenu.filter(
      menuItem => menuItem.name.toLowerCase() !== 'purchases',
    )
  }

  return visibleMenu
}
