import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

import { googleEvent } from '@/utils/events'
import {
  headerMenuMobile,
  HeaderVariables,
} from '@/components/Layout/components/Header/components/Menu/constants'

function Info() {
  const onPhoneClick = () => {
    googleEvent('')
  }

  function handleCallClick(event: React.MouseEvent) {
    event.preventDefault()

    // openModal(
    //   CallbackForm,
    //   {
    //     request: values =>
    //       handleSubmitCallbackForm(values, {
    //         type: phoneCallbackGoogleEvent ?? '',
    //       }),
    //     title: 'Заказать обратный звонок',
    //   },
    //   { components: { Overlay: StyledOverlay }, scrollLockDisabled: true },
    // )
  }

  return (
    <Component>
      <Menu>
        {headerMenuMobile.map((menuItem, index) => (
          <React.Fragment key={index}>
            <MenuLabel>
              <MenuLabelLink
                to={menuItem.link ?? ''}
                target={menuItem.isNewTab ? '_blank' : '_self'}
              >
                {menuItem.label}
              </MenuLabelLink>
            </MenuLabel>
            <MenuList>
              {menuItem.children.map((menuChildItem, index) => {
                let label = menuChildItem.label
                let link = menuChildItem.link

                if (menuChildItem.label === HeaderVariables.Phone) {
                  label = '+375 29 677-00-99'
                  link = 'tel:+375296770099'
                }

                return (
                  <MenuItem key={index}>
                    {link === HeaderVariables.Call ? (
                      <button
                        type='button'
                        onClick={event => {
                          event.stopPropagation()
                          handleCallClick(event)
                        }}
                      >
                        {label}
                      </button>
                    ) : (
                      <Link
                        to={link ?? ''}
                        target={menuChildItem.isNewTab ? '_blank' : '_self'}
                        onClick={() =>
                          link && link.startsWith('tel:+') && onPhoneClick()
                        }
                      >
                        {label}
                      </Link>
                    )}
                  </MenuItem>
                )
              })}
            </MenuList>
          </React.Fragment>
        ))}
      </Menu>
    </Component>
  )
}

export default Info

const Component = styled.div`
  padding: 25px 20px;
`

const Menu = styled.div``

const MenuLabel = styled.p`
  margin-bottom: 16px;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  text-transform: uppercase;
`

const MenuLabelLink = styled(Link)`
  color: ${props => props.theme.black200};
`

const MenuList = styled.ul``

const MenuItem = styled.li`
  font-size: 14px;
  line-height: 20px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  button,
  a {
    color: ${props => props.theme.main};
  }
`
