import { canUseDOM } from '@/utils/common'

declare global {
  interface Window {
    dataLayer?: Array<IArguments>
  }
}

function execCommand(..._args: Array<unknown>) {
  if (!canUseDOM()) return

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push(arguments)
}

export const gtag = {
  /**
   * Reference:
   * {@link https://developers.google.com/gtagjs/reference/api#config}
   */
  config(targetId: string, configInfo: { [key: string]: unknown } = {}) {
    execCommand('config', targetId, configInfo)
  },
  /**
   * Reference:
   * {@link https://developers.google.com/gtagjs/reference/api#get}
   */
  get(target: string, fieldName: string, callback: Function) {
    execCommand('get', target, fieldName, callback)
  },
  /**
   * Reference:
   * {@link https://developers.google.com/gtagjs/reference/api#set}
   */
  set(params: { [key: string]: unknown }) {
    execCommand('set', params)
  },
  /**
   * Reference:
   * {@link https://developers.google.com/gtagjs/reference/api#event}
   */
  event(eventName: string, eventParams: { [key: string]: unknown } = {}) {
    execCommand('event', eventName, eventParams)
  },

  dataLayerPush(pushParams: { [key: string]: unknown } = {}) {
    execCommand(pushParams)
  },

  js(startDate: Date) {
    execCommand('js', startDate)
  },
} as const

export type GoogleEventParamVariant = 'car' | 'stockStatus'

export type GoogleEventParamType = Partial<
  Record<GoogleEventParamVariant, string>
>

export function googleEvent(event: string, params?: GoogleEventParamType) {
  console.log(
    `Google Event: ${event}${
      params ? `\nParams: ${JSON.stringify(params)}` : ''
    }`,
  )
  gtag.event(event, params)
}
