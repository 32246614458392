import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { useAuth } from '@/providers/Auth'
import { media } from '@/utils/mixin'

function Logout() {
  const { signOutThunk } = useAuth()

  return (
    <Component>
      <ComponentContainer>
        <Header>
          <HeaderLink onClick={() => signOutThunk()}>Выйти</HeaderLink>
        </Header>
        <List>
          <Item>
            <StyledLink to={'/personal'}>Личный кабинет</StyledLink>
          </Item>
        </List>
      </ComponentContainer>
    </Component>
  )
}

export default Logout

const Component = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  width: 16.6666666667%;
  padding: 0 15px;
  margin-bottom: 30px;

  ${media.tablet(css`
    width: 100%;
    padding: 0;
  `)}
`

const ComponentContainer = styled.div`
  flex: 1;
`

const Header = styled.h3`
  flex: 1;
  margin: 0 0 16px 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: right;

  ${media.tablet(css`
    text-align: left;
  `)}
`

const List = styled.ul`
  text-align: right;

  ${media.tablet(css`
    text-align: left;
  `)}
`

const Item = styled.li`
  margin: 0 0 16px;

  a {
    transition: all 0.3s linear;

    &:hover {
      color: ${props => props.theme.main};
    }
  }
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.gray500};
`

const HeaderLink = styled.button`
  color: ${props => props.theme.gray500};
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition:
    color 0.15s,
    border 0.15s;

  &:hover {
    color: ${props => props.theme.main};
    text-decoration: none;
    border-bottom: 1px solid ${props => props.theme.main};
  }
`
