import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { media } from '@/utils/mixin'

function NoCars() {
  return (
    <Card>
      <Text>Автомобили не найдены</Text>
    </Card>
  )
}

const Card = styled.div`
  background-color: ${props => props.theme.white};
  padding: 30px 40px;
  margin: 30px 0;

  ${media.tabletSmall(css`
    padding: 30px 20px;
  `)}
`

const Text = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${props => props.theme.black100};
  margin: 0;

  ${media.mobile(css`
    font-size: 20px;
    line-height: 28px;
  `)}
`

export default NoCars
