import React from 'react'

import Cabinet from '@/modules/Cabinet'

function Requests() {
  return (
    <Cabinet activeTab={'REQUESTS'}>REQUESTS</Cabinet>
  )
}

export default Requests

