import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { FooterContainer } from '../FooterContainer'

import { media } from '@/utils/mixin'

function Bottom() {
  return (
    <Component id='bottom-footer'>
      <FooterContainer width={1280}>
        <Grid>
          <Copyright>© Атлант-М 2007 – 2024</Copyright>
        </Grid>
      </FooterContainer>
    </Component>
  )
}

export default Bottom

const Component = styled.div`
  padding: 20px 0 30px;
  background: ${props => props.theme.white};

  @media (min-width: 1200px) {
    padding: 30px 0;
  }
`

const Grid = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${media.mobileUp(css`
    align-items: flex-start;
  `)};

  @media (min-width: 1200px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Copyright = styled.div`
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  color: ${props => props.theme.blue100};

  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 24px;
  }
`
