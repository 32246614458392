import React, { useState } from 'react'
import { Formik, FormikErrors, FormikHelpers } from 'formik'
import moment from 'moment/moment'

import PersonalDataForm, { PersonalDataFormValues } from './PersonalDataForm'

import { updatePersonalDataInTheProfile } from '@/services/users/users-service'
import { PersonalDataPayload } from '@/services/users/typings'
import { StringField } from '@/typings/common'
import { convertRequestErrorToMap } from '@/utils/common'
import { useProfile } from '@/providers/Profile'

interface Props {
  setFormVisible: (value: boolean) => void
  setLoading: (value: boolean) => void
  formDefaultValue: PersonalDataPayload
}

function PersonalDataFormContainer({
  setFormVisible,
  setLoading,
  formDefaultValue,
}: Props) {
  const { getProfileDataAction } = useProfile()
  const [gender, setGender] = useState<StringField>(
    formDefaultValue.gender ?? null,
  )
  const [birthdate, setBirthdate] = useState<Date | null>(
    formDefaultValue.birthdate ? new Date(formDefaultValue.birthdate) : null,
  )

  async function handleSubmit(
    values: PersonalDataFormValues,
    formikHelpers: FormikHelpers<PersonalDataFormValues>,
  ) {
    setLoading(true)

    const payload: PersonalDataFormValues = {
      ...values,
      birthdate: moment(birthdate).format('YYYY-MM-DD'),
      gender,
    }

    await updatePersonalDataInTheProfile(payload)
      .then(data => {
        getProfileDataAction().finally(() => {
          setLoading(false)
          setFormVisible(false)
        })
      })
      .catch((error: any) => {
        const errorMap = convertRequestErrorToMap(
          error,
        ) as FormikErrors<PersonalDataFormValues>
        formikHelpers.setErrors(errorMap)
        setLoading(false)
      })
  }

  return (
    <Formik<PersonalDataFormValues>
      onSubmit={handleSubmit}
      initialValues={{
        firstName: formDefaultValue.firstName,
        lastName: formDefaultValue.lastName,
        patronymic: formDefaultValue.patronymic,
        birthdate: formDefaultValue.birthdate,
        gender: formDefaultValue.gender,
      }}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {formProps => (
        <PersonalDataForm
          {...formProps}
          gender={gender}
          setGender={(value: StringField) => setGender(value)}
          setFormVisible={setFormVisible}
          birthdate={birthdate}
          setBirthdate={(value: Date | null) => setBirthdate(value)}
        />
      )}
    </Formik>
  )
}

export default PersonalDataFormContainer
