import React from 'react'
import { Form, FormikProps } from 'formik'
import styled from '@emotion/styled'

import { DeletePhonePayload } from '@/services/users/typings'
import Spinner from '@/components/Spinner'

export type DeletePhoneFormValues = DeletePhonePayload

type Props = FormikProps<DeletePhonePayload> & {
  isLoading: boolean
  phone: string | null
}

function DeletePhoneForm({
  isSubmitting,
  isLoading,
  phone,
  handleChange,
  setFieldValue,
  values,
  errors,
  ...formProps
}: Props) {
  return (
    <Form autoComplete='off' noValidate>
      <TextFieldWrapper>
        <Text>
          хотите удалить <span>{phone}</span>
        </Text>
        <SubmitButton disabled={isLoading} type={'submit'}>
          {isLoading ? <Spinner absolute={true} /> : 'да'}
        </SubmitButton>
      </TextFieldWrapper>
    </Form>
  )
}

export default DeletePhoneForm

const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Text = styled.div`
  margin-right: 30px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  span {
    color: ${props => props.theme.main};
  }
`

const SubmitButton = styled.button`
  position: relative;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  text-overflow: ellipsis;
  text-transform: uppercase;
  padding: 11px 52px;
  text-decoration: none;
  border-radius: 20px;
  height: 40px;
  width: 150px;
  background: ${props => props.theme.main};
  color: ${props => props.theme.white};
  transition: 0.3s;

  &:hover {
    background: ${props => props.theme.main100};
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;

    &:hover {
      background: ${props => props.theme.main};
    }
  }
`
