import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { A11y, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import NoImageCar from '@/assets/images/defaultStockCar.png'
import { ReactComponent as ChevronRightIcon } from '@/assets/svg/chevron-right-24dp.svg'
import NumberPlate from '@/components/NumberPlate'
import { media } from '@/utils/mixin'
import { UserProfilePreviewCar } from '@/services/users/typings'

interface Props {
  slides: UserProfilePreviewCar[]
}

function Slider({ slides }: Props) {
  const isDisabled = slides.length <= 1

  const settings = isDisabled
    ? {
        watchOverflow: true,
      }
    : {
        loop: true,
        navigation: {
          prevEl: '.prev',
          nextEl: '.next',
        },
      }

  return (
    <Component>
      {!isDisabled && (
        <>
          <Prev className='prev'>
            <ChevronRightIcon />
          </Prev>
          <Next className='next'>
            <ChevronRightIcon />
          </Next>
        </>
      )}

      <StyledSwiper
        {...settings}
        disabled={isDisabled}
        modules={[Navigation, A11y]}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <Slide>
              <Group>
                <GroupInfo>
                  <Title>{slide.name}</Title>
                  {slide.plate && <NumberPlate value={slide.plate} />}
                </GroupInfo>
                <GroupImage>
                  <img loading='lazy' src={NoImageCar} alt='' />
                </GroupImage>
              </Group>
              <Mileage>
                {slide.mileage ? (
                  <MileageText>
                    Пробег <MileageValue>{slide.mileage}</MileageValue> км
                  </MileageText>
                ) : null}
              </Mileage>
            </Slide>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </Component>
  )
}

export default Slider

const Component = styled.div`
  position: relative;
  flex: 0 0 auto;
  width: 50%;

  padding: 0 15px;
  margin: 0 0 30px;

  ${media.tablet(css`
    width: 100%;
    padding: 0;
  `)}
`

const StyledSwiper = styled(Swiper)<{ disabled?: boolean }>`
  .swiper-slide {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: default;

        &:active {
          cursor: default;
        }
      `}
  }
}
`

const Slide = styled.div`
  padding: 0 30px;
`

const Group = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.tablet(css`
    justify-content: stretch;
  `)}
`

const GroupInfo = styled.div`
  width: calc(100% - 250px);

  ${media.tablet(css`
    width: 100%;
  `)}
`

const Title = styled.h3`
  color: ${props => props.theme.black200};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  max-width: 205px;
  margin: 0 0 6px 0;
  text-transform: uppercase;
`

const GroupImage = styled.div`
  max-width: 250px;
`

const Mileage = styled.div`
  color: ${props => props.theme.blue100};
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin: 8px 0;
`

const MileageText = styled.span``

const MileageValue = styled.span``

const Prev = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 4px;
  transform: translateY(-50%);
  z-index: 99;

  svg {
    transform: rotateY(180deg);
    path {
      stroke: #636363;
    }
  }

  &:hover {
    svg {
      path {
        stroke: #434343;
      }
    }
  }

  &:active {
    opacity: 0.8;
  }

  &.swiper-button-disabled {
    cursor: default;
    opacity: 0.5;
  }
`

const Next = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  z-index: 99;

  svg {
    path {
      stroke: #636363;
    }
  }

  &:hover {
    svg {
      path {
        stroke: #434343;
      }
    }
  }

  &:active {
    opacity: 0.8;
  }

  &.swiper-button-disabled {
    cursor: default;
    opacity: 0.5;
  }
`
