import React, { PropsWithChildren, useCallback, useState } from 'react'
import { ThemeProvider as ThemeProviderStyledComponents } from '@emotion/react'

import { getColorsByTheme, getInitialTheme } from './helpers'
import { ThemeTypes } from './types'
import { ThemeContext } from './ThemeContext'

import { useIsomorphicLayoutEffect } from '@/hooks/useIsomorphicLayoutEffect'

export const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [theme, setNewTheme] = useState<ThemeTypes>('mainLight')

  const setTheme = useCallback((theme: ThemeTypes) => {
    setNewTheme(theme)
  }, [])

  useIsomorphicLayoutEffect(() => {
    setTheme(getInitialTheme())
  }, [setTheme])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ThemeProviderStyledComponents theme={getColorsByTheme(theme)}>
        {children}
      </ThemeProviderStyledComponents>
    </ThemeContext.Provider>
  )
}
