import React from 'react'

import * as S from './CabinetTextInput.style'

type Props = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'> & {
  className?: string
  prefix?: React.ElementType<any, keyof React.JSX.IntrinsicElements> | undefined
  invalid?: boolean
  badge?: React.ReactNode
}

export type InputRef = HTMLInputElement

function CabinetTextInput(
  { className, prefix, disabled, invalid, badge, ...rest }: Props,
  ref: React.Ref<InputRef>,
) {
  function renderPrefix() {
    if (!prefix) return null
    if (React.isValidElement(prefix)) return prefix
    return <S.Prefix as={prefix} />
  }

  return (
    <S.Container className={className}>
      {renderPrefix()}
      <S.Input
        ref={ref}
        {...rest}
        withPrefix={Boolean(prefix)}
        disabled={disabled}
        invalid={invalid}
      />
      {badge && <S.Badge>{badge}</S.Badge>}
    </S.Container>
  )
}

export default React.forwardRef<InputRef, Props>(CabinetTextInput)
