import React from 'react'
import styled from '@emotion/styled/macro'
import { css } from '@emotion/react'

import { ReactComponent as TickIcon } from '@/assets/svg/checkbox-tick.svg'
import { media } from '@/utils/mixin'
import { StringField } from '@/typings/common'

interface Props {
  isRemovable?: boolean
  text: StringField
  label?: string
  deleteItem?: () => void
}

function UnorderedListsItem({ isRemovable, text, label, deleteItem }: Props) {
  function getFieldText(text: StringField, label?: string) {
    return label && text
      ? `${label}: ${text}`
      : label
        ? label
        : text
          ? text
          : ''
  }

  return (
    <Item isFilledIn={text} isRemovable={isRemovable}>
      <TickIcon />
      <Text>{getFieldText(text, label)}</Text>
      {isRemovable && <RemoveBtn onClick={deleteItem}>Удалить</RemoveBtn>}
    </Item>
  )
}

const RemoveBtn = styled.button`
  font-size: 16px;
  line-height: 100%;
  color: ${props => props.theme.main};
  text-decoration: none;
  border-bottom: 1px solid transparent;
  opacity: 0;
  visibility: hidden;
  transition: 0.15s;
  margin-left: 15px;

  &:hover {
    border-color: ${props => props.theme.main};
  }
`

const Item = styled.li<{
  isFilledIn: StringField
  isRemovable?: boolean
}>`
  display: flex;
  align-items: center;

  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  color: ${props => props.theme.gray500};

  ${media.mobile(css`
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  `)}

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    position: relative;
    left: -2px;
    width: 18px;
    height: 18px;
    margin-right: 7px;

    path {
      fill: ${props => props.theme.gray500};
      stroke: ${props => props.theme.gray500};
    }
  }

  ${props =>
    props.isFilledIn &&
    css`
      color: ${props.theme.blue100};

      svg {
        path {
          fill: ${props.theme.main};
          stroke: ${props.theme.main};
        }
      }
    `}

  ${props =>
    props.isRemovable
      ? css`
          cursor: pointer;

          ${media.mobile(css`
            cursor: default;
          `)}

          &:hover {
            ${RemoveBtn} {
              opacity: 1;
              visibility: visible;
            }
          }
        `
      : ''}
`

const Text = styled.span``

export default UnorderedListsItem
