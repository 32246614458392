import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Link } from 'react-router-dom'

import { FooterContainer } from '../FooterContainer'

import Socials from './components/Socials'
import Apps from './components/Apps'
import Pay from './components/Pay'

import { googleEvent } from '@/utils/events'
import { media } from '@/utils/mixin'
import {
  footerDisclaimer,
  footerMenu,
  FooterVariables,
} from '@/components/Layout/components/Footer/components/constants'
import { Nullable } from '@/typings/common'
import Dealers from '@/components/Layout/components/Footer/components/Dealers'

type Props = {
  title?: string
  text?: string
}

function Main({ title }: Props) {
  function handleCallClick(event: React.MouseEvent) {
    event.preventDefault()
    // openModal(
    //   CallbackForm,
    //   {
    //     request: values =>
    //       handleSubmitCallbackForm(values, {
    //         type: phoneCallbackGoogleEvent ?? '',
    //       }),
    //     title: 'Заказать обратный звонок',
    //   },
    //   { components: { Overlay: StyledOverlay }, scrollLockDisabled: true },
    // )
  }

  const onPhoneClick = () => {
    googleEvent('')
  }

  return (
    <Component>
      <FooterContainer width={1280}>
        {title && <Title dangerouslySetInnerHTML={{ __html: title ?? '' }} />}
        <Row>
          {footerMenu.map((menuColumn, index) => {
            const variableIndex = menuColumn.label.indexOf(':')
            const menuTitle =
              variableIndex !== -1
                ? menuColumn.label.substring(0, variableIndex).trim()
                : menuColumn.label
            const menuView = menuColumn.label.substring(variableIndex)

            return (
              <Col
                key={index}
                colWidth={menuView === FooterVariables.Dealers ? '520px' : null}
                tabletFullWidth={menuView === FooterVariables.Dealers}
              >
                <SimpleMenu>
                  <SimpleMenuHeader
                    to={menuColumn.link ?? ''}
                    target={menuColumn.isNewTab ? '_blank' : '_self'}
                  >
                    {menuTitle}
                  </SimpleMenuHeader>

                  {menuView === FooterVariables.Dealers ? (
                    <Dealers />
                  ) : menuColumn.children ? (
                    <SimpleMenuList>
                      {menuColumn.children.map((menuChildItem, index) => {
                        let label = menuChildItem.label
                        let link = menuChildItem.link

                        if (menuChildItem.label === FooterVariables.Phone) {
                          label = '+375 29 677-00-99'
                          link = 'tel:+375296770099'
                        }

                        return (
                          <SimpleMenuItem key={index}>
                            {link === FooterVariables.Call ? (
                              <SimpleMenuLinkButton onClick={handleCallClick}>
                                {label}
                              </SimpleMenuLinkButton>
                            ) : (
                              <SimpleMenuLink
                                to={link ?? ''}
                                target={
                                  menuChildItem.isNewTab ? '_blank' : '_self'
                                }
                                onClick={() =>
                                  link &&
                                  link.startsWith('tel:+') &&
                                  onPhoneClick()
                                }
                              >
                                {label}
                              </SimpleMenuLink>
                            )}
                          </SimpleMenuItem>
                        )
                      })}
                    </SimpleMenuList>
                  ) : null}

                  {menuView === FooterVariables.Contacts ? (
                    <ExternalApps>
                      <Socials />
                      <Apps />
                    </ExternalApps>
                  ) : null}
                </SimpleMenu>
              </Col>
            )
          })}
        </Row>

        <Separator />

        <Sub>
          <SubGrid>
            <Info
              dangerouslySetInnerHTML={{ __html: footerDisclaimer ?? '' }}
            />
            <SubPay>
              <Pay />
            </SubPay>
          </SubGrid>
        </Sub>
      </FooterContainer>
    </Component>
  )
}

export default Main

const Component = styled.div`
  background: ${props => props.theme.black200_1};
  color: ${props => props.theme.white};
  border-top: 1px solid ${props => props.theme.black100};
  padding: 30px 0;

  ${media.mobileUp(css`
    padding: 40px 0;
  `)};

  @media (min-width: 1200px) {
    padding: 60px 0;
  }
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  ${media.mobile(css`
    display: block;
    margin: 0;
  `)}
`

const Title = styled.div`
  text-align: center;
  font-size: 19px;
  line-height: 28px;
  color: ${props => props.theme.white};
  margin-top: -20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${props => props.theme.blue100};
  margin-bottom: 20px;

  a {
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom-color: ${props => props.theme.white};
    }
  }

  ${media.tablet(css`
    font-size: 16px;
    line-height: 20px;
    margin-top: 0;
  `)}
`

const Col = styled.div<{
  colWidth: Nullable<string>
  tabletFullWidth: boolean
}>`
  max-width: 100%;
  padding: 0 10px;
  margin: 0 0 30px;
  flex: 0 0 auto;
  width: ${props => (props.colWidth ? props.colWidth : '16.6666666667%')};

  ${media.tablet(css`
    width: 33.3333333%;
  `)}

  ${media.mobile(css`
    width: 100%;
    margin: 30px 0 0 0;
    padding: 0;

    &:first-child {
      margin-top: 0;
    }
  `)}

  ${({ tabletFullWidth }) =>
    tabletFullWidth &&
    css`
      ${media.tablet(css`
        width: 100%;
        order: -1;
      `)}
    `};
`

const SimpleMenu = styled.div``

const SimpleMenuHeader = styled(Link)`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  border-bottom: 1px solid transparent;
  color: ${props => props.theme.white};

  &:hover {
    color: ${props => props.theme.white};
    border-bottom: 1px solid ${props => props.theme.white};
  }
`

const SimpleMenuList = styled.ul`
  margin-top: 16px;

  ${media.mobile(css`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 15px;
  `)}
`

const SimpleMenuItem = styled.li`
  font-size: 14px;
  line-height: 20px;

  &:not(:last-child) {
    margin: 0 0 16px;
  }
`

const ExternalApps = styled.div`
  margin-top: 35px;
`

const SimpleMenuLink = styled(Link)`
  color: ${props => props.theme.white};
  border-bottom: 1px solid transparent;

  &:hover {
    color: ${props => props.theme.white};
    border-bottom: 1px solid ${props => props.theme.white};
  }
`

const SimpleMenuLinkButton = styled.button`
  color: ${props => props.theme.white};
  border-bottom: 1px solid transparent;

  &:hover {
    color: ${props => props.theme.white};
    border-bottom: 1px solid ${props => props.theme.white};
  }
`

const Separator = styled.hr`
  margin: 30px 0 20px 0;
  border: none;
  border-top: 1px solid ${props => props.theme.blue100};

  ${media.mobileUp(css`
    margin: 30px 0;
  `)}
`

const Sub = styled.div`
  margin-top: 20px;

  ${media.mobileUp(css`
    margin-top: 30px;
  `)}
`

const SubGrid = styled.div`
  ${media.mobileUp(css`
    display: flex;
    margin: 0 -15px;
    align-items: center;
  `)}
`

const Info = styled.div`
  flex: 0 1 auto;
  color: ${props => props.theme.blue100};
  font-size: 12px;
  line-height: 20px;

  a {
    color: ${props => props.theme.blue100};
    text-decoration: none;
    border-bottom: 1px solid ${props => props.theme.blue100};

    &:hover {
      color: ${props => props.theme.white};
      text-decoration: none;
      border-bottom: 1px solid transparent;
    }
  }

  ${media.mobileUp(css`
    max-width: 50%;
    padding: 0 15px;
  `)}
`

const SubPay = styled.div`
  margin-top: 20px;

  ${media.mobileUp(css`
    padding: 0 15px;
    text-align: right;
    margin-top: 0;
  `)}
`
