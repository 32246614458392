import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import { useCabinetDataTable } from '../../../../useCabinetDataTable'

import CabinetSearchInput from '@/components/PersonalCabinetForms/components/CabinetSearchInput'
import CabinetCheckbox from '@/components/PersonalCabinetForms/components/CabinetCheckbox'
import CabinetSelectForFilters from '@/components/PersonalCabinetForms/components/CabinetSelectForFilters'
import { media } from '@/utils/mixin'
import {
  IDataTableCheckbox,
  IDataTableSearch,
  IDataTableSort,
} from '@/modules/Cabinet/components/CabinetDataTable/types'
import CabinetSelect, {
  CabinetSelectProps,
} from '@/components/PersonalCabinetForms/components/CabinetSelect/CabinetSelect'

interface Props {
  search?: IDataTableSearch
  sort?: IDataTableSort
  checkbox?: IDataTableCheckbox
  select?: CabinetSelectProps
}

function Filters({ search, sort, checkbox, select }: Props) {
  const {
    updateSearchQuery,
    isCheckboxChecked,
    updateCheckboxStatus,
    sortValue,
    updateSortValue,
    sortVisible,
  } = useCabinetDataTable()

  React.useLayoutEffect(() => {
    if (sort?.options) {
      updateSortValue(sort.options[0])
    }

    if (checkbox?.defaultValue) {
      updateCheckboxStatus(checkbox?.defaultValue)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Components>
      <Left>
        {search?.handler && (
          <Search>
            <CabinetSearchInput
              setSearchQuery={updateSearchQuery}
              placeholder={search.placeholder}
            />
          </Search>
        )}
        {checkbox?.label && (
          <CheckBox>
            <CabinetCheckbox
              name={checkbox.name}
              text={checkbox.label}
              isChecked={isCheckboxChecked}
              setChecked={updateCheckboxStatus}
            />
          </CheckBox>
        )}
        {select?.options && select.options.length > 1 && (
          <Select>
            <CabinetSelect {...select} />
          </Select>
        )}
      </Left>
      <Right>
        {sortVisible && sort?.options && sort.options.length > 0 && (
          <CabinetSelectForFilters
            value={sortValue ?? sort.options[0]}
            options={sort.options}
            onChange={updateSortValue}
          />
        )}
      </Right>
    </Components>
  )
}

const Components = styled.div`
  display: flex;
  align-items: center;

  ${media.mobile(css`
    display: block;
  `)}
`

const Left = styled.div`
  flex: 1 1 auto;

  display: flex;
  align-items: center;

  ${media.mobile(css`
    display: block;
  `)}
`

const Right = styled.div`
  ${media.mobile(css`
    margin-top: 15px;
    width: 100%;
  `)}
`

const Search = styled.div`
  flex: 0 0 300px;
  max-width: 300px;
  margin-right: 20px;

  ${media.mobile(css`
    max-width: 100%;
    margin-right: 0;
  `)}
`

const CheckBox = styled.div`
  margin-right: 20px;

  ${media.mobile(css`
    margin-top: 20px;
    margin-right: 0;
  `)}
`

const Select = styled.div`
  flex: 0 0 300px;
  max-width: 300px;
`

export default Filters
