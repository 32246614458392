import React, { useState } from 'react'
import { Formik, FormikErrors, FormikHelpers } from 'formik'

import AddPhoneForm, { AddPhoneFormValues } from './AddPhoneForm'

import { addPhone } from '@/services/users/users-service'
import { convertRequestErrorToMap } from '@/utils/common'
import { useProfile } from '@/providers/Profile'

interface Props {
  closeModal: () => void
}

function AddPhoneFormContainer({ closeModal }: Props) {
  const { getProfileDataAction } = useProfile()
  const [isPhoneSend, setPhoneSend] = useState<string | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false)

  function handlePhoneSend(
    values: AddPhoneFormValues,
    formikHelpers: FormikHelpers<AddPhoneFormValues>,
  ) {
    addPhone(values)
      .then(() => {
        formikHelpers.resetForm()
        setPhoneSend(values.phone)
      })
      .catch(error => {
        const errorMap = convertRequestErrorToMap(
          error,
        ) as FormikErrors<AddPhoneFormValues>
        formikHelpers.setErrors(errorMap)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  function handleCheckCode(
    values: AddPhoneFormValues,
    formikHelpers: FormikHelpers<AddPhoneFormValues>,
  ) {
    addPhone(values)
      .then(() => {
        getProfileDataAction().finally(() => {
          setLoading(false)
          closeModal()
        })
      })
      .catch((error: any) => {
        setLoading(false)
        const errorMap = convertRequestErrorToMap(
          error,
        ) as FormikErrors<AddPhoneFormValues>
        formikHelpers.setErrors(errorMap)
      })
  }

  function handleSubmit(
    values: AddPhoneFormValues,
    formikHelpers: FormikHelpers<AddPhoneFormValues>,
  ) {
    setLoading(true)
    isPhoneSend
      ? handleCheckCode(
          {
            phone: isPhoneSend,
            code: values.code,
          },
          formikHelpers,
        )
      : handlePhoneSend({ ...values, phone: values.phone }, formikHelpers)
  }

  return (
    <Formik<AddPhoneFormValues>
      onSubmit={handleSubmit}
      initialValues={{
        phone: '',
        code: null,
      }}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {formProps => (
        <AddPhoneForm
          {...formProps}
          isLoading={isLoading}
          isPhoneSend={isPhoneSend}
        />
      )}
    </Formik>
  )
}

export default AddPhoneFormContainer
