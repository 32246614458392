import React from 'react'
import styled from '@emotion/styled/macro'

import Rating from '@/components/Rating'

interface Props {
  name: string
  url?: string
  rating: number
  reviewsCount: number
}

function Dealer({ name, url, rating, reviewsCount }: Props) {
  return (
    <Component>
      <Link href={url}>{name}</Link>
      <RatingLink href={url}>
        <RatingWrapper>
          <Stars>
            <Rating count={5} value={rating} />
          </Stars>
          <Reviews>
            <ReviewsCount>{reviewsCount}</ReviewsCount>{' '}
          </Reviews>
        </RatingWrapper>
      </RatingLink>
    </Component>
  )
}

export default Dealer

const Component = styled.div``

const Link = styled.a`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: ${props => props.theme.white};
  border-bottom: 1px solid transparent;

  &:hover {
    color: ${props => props.theme.white};
    border-bottom: 1px solid ${props => props.theme.white};
  }
`

const RatingWrapper = styled.div`
  display: flex;
  align-content: center;
`

const Stars = styled.div`
  display: flex;
  align-items: center;
`

const Reviews = styled.div`
  flex: 0 1 auto;
  margin-left: 10px;
  color: ${props => props.theme.white};
  font-size: 14px;
  line-height: 24px;
  transition: 0.15s;
  white-space: nowrap;
  text-decoration: underline;
`

const ReviewsCount = styled.span``

const RatingLink = styled.a`
  &:hover {
    ${Reviews} {
      text-decoration: none;
    }
  }
`
