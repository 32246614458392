import React, { useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { css } from '@emotion/react'
import styled from '@emotion/styled/macro'

import BonusCard from './components/BonusCard'

import { media } from '@/utils/mixin'
import { AddPhotoPayload } from '@/services/users/typings'
import AvatarUrl from '@/assets/images/avatar.png'
import { updatePhoto } from '@/services/users/users-service'
import { FormOverlay } from '@/components/PersonalCabinetForms/components/CabinetForm.style'
import Spinner from '@/components/Spinner'
import { ReactComponent as Cross } from '@/assets/svg/close-24dp.svg'
import { useProfile } from '@/providers/Profile'

function PersonalBlock() {
  const { profile, getProfileDataAction } = useProfile()

  const imageRef = useRef<HTMLImageElement>(null)
  const [isLoading, setLoading] = useState<boolean>(false)

  if (!profile || !profile.data) {
    return null
  }

  const { name, photo } = profile.data

  async function chooseImage(event: any) {
    if (!event.target.files[0]) {
      return
    }

    setLoading(true)

    const base64 = await getBase64(event.target.files[0])

    const payload = {
      data: base64,
    }

    await changeImage(payload)
  }

  function deleteImage() {
    setLoading(true)

    const payload: AddPhotoPayload = {
      data: null,
    }

    changeImage(payload).catch((error: any) => {
      console.log(error)
      setLoading(false)
      toast.error(`Error!`)
    })
  }

  async function changeImage(payload: AddPhotoPayload) {
    await updatePhoto(payload)
      .then(() => {
        getProfileDataAction().finally(() => {
          setLoading(false)
        })
      })
      .catch((error: any) => {
        console.log(error)
        setLoading(false)
        toast.error(`${error.body?.message}`)
      })
  }

  async function getBase64(file: any): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () =>
        resolve(reader.result ? reader.result.toString() : null)
      reader.onerror = error => reject(error)
    })
  }

  return (
    <Block>
      <BlockInner>
        <Left isLoading={isLoading}>
          <InputFile
            id='profile-image'
            type='file'
            accept='image/*'
            onChange={chooseImage}
          />
          <InputFileLabel htmlFor='profile-image'> </InputFileLabel>
          <Image>
            <ImageHidden ref={imageRef} />
            <img loading='lazy' src={photo ? photo : AvatarUrl} alt='avatar' />
            <FormOverlay isLoading={isLoading}>
              <Spinner absolute={true} isCabinet={true} />
            </FormOverlay>
          </Image>
          {photo && (
            <DeleteImage onClick={deleteImage} isLoading={isLoading}>
              <Cross />
            </DeleteImage>
          )}
          <FigureCaption isLoading={isLoading}>изменить фото</FigureCaption>
        </Left>
        <Center>
          <Text>Добро пожаловать, {name}!</Text>
        </Center>
        <Right>
          <BonusCard />
        </Right>
      </BlockInner>
    </Block>
  )
}

const Block = styled.div`
  background-color: ${props => props.theme.white};
  position: relative;
  padding: 30px;

  ${media.mobile(css`
    padding: 20px;
  `)}
`

const BlockInner = styled.div`
  display: flex;

  ${media.tabletSmall(css`
    flex-direction: column;
    align-items: center;
  `)}
`

const Center = styled.div`
  flex: 1 1 auto;
  margin-right: 30px;

  ${media.tabletSmall(css`
    order: -1;
    margin: 0 0 20px;
  `)}

  ${media.mobile(css`
    order: 0;
    margin-top: 21px;
  `)}
`

const Right = styled.div`
  flex: 0 0 317px;
  margin-left: 50px;

  ${media.tabletSmall(css`
    flex: 1 1 auto;
    min-width: 317px;
    margin: 20px 0 0;
  `)}

  ${media.mobile(css`
    min-width: 100%;
    margin: 0 10px;
  `)}
`

const Image = styled.div`
  position: relative;
  width: 184px;
  height: 184px;
  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0 2px 30px 10px rgb(0 97 237 / 20%);

  & > div,
  picture,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const DeleteImage = styled.button<{ isLoading: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  right: 20%;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  color: ${props => props.theme.white};
  background: ${props => props.theme.gray500};
  transition: 0.15s;
  z-index: 20;

  &:hover {
    background: ${props => props.theme.main};
  }

  svg {
    width: 12px !important;
    height: 12px !important;
  }

  ${({ theme }) =>
    media.tabletSmall(css`
      right: -2%;
      background: ${theme.main};
    `)};

  ${({ isLoading }) =>
    isLoading &&
    css`
      transition: all 0s linear;
      background: transparent !important;
    `}
`

const FigureCaption = styled.span<{ isLoading: boolean }>`
  display: block;
  margin-top: 36px;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.main};
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: all 0.3s linear;

  ${media.mobile(css`
    margin-top: 30px;
  `)}

  ${({ isLoading, theme }) =>
    isLoading &&
    css`
      transition: all 0s linear;
      color: ${theme.white} !important;
    `}
`

const Text = styled.p`
  display: block;
  word-break: break-word;

  color: ${props => props.theme.black100};
  font-weight: 700;
  font-size: 32px;
  line-height: 42px;

  ${media.tabletSmall(css`
    font-size: 26px;
    line-height: 36px;
  `)}

  ${media.mobile(css`
    font-size: 20px;
    line-height: 24px;
  `)}
`

const Left = styled.div<{ isLoading: boolean }>`
  flex: 0 0 330px;
  max-width: 330px;
  margin-right: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  cursor: pointer;

  ${media.tablet(css`
    flex: 0 0 254px;
    max-width: 254px;
  `)}

  ${media.tabletSmall(css`
    margin: 0;
    flex: 1 1 auto;
  `)}

  ${({ isLoading, theme }) =>
    isLoading
      ? css`
          pointer-events: none;
          cursor: default;
        `
      : css`
          &:hover {
            ${FigureCaption} {
              text-decoration-color: ${theme.main};
            }
          }
        `}
`

const InputFile = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const InputFileLabel = styled.label`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  cursor: pointer;
`

const ImageHidden = styled.img`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
`

export default PersonalBlock
