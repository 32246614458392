import React from 'react'
import styled from '@emotion/styled'

import Promo from './components/Promo'
import Actions from './components/Actions'
import Slider from './components/Slider'
import Menu from './components/Menu'
import Logout from './components/Logout'

import { useProfile } from '@/providers/Profile'
import { useAuth } from '@/providers/Auth'
import { isNotNullish } from '@/utils/common'
import SignIn from '@/components/Layout/components/Header/components/SignIn'
import Dropdown from '@/components/Layout/components/Header/components/Dropdown'
import NoCars from '@/components/Layout/components/Header/components/User/components/NoCars'

function User() {
  const { profile, preview } = useProfile()
  const { status } = useAuth()
  const isUserAuthorized = status === 'AUTHORIZED'
  const slides =
    preview?.data?.cars && preview?.data?.cars.length > 0
      ? preview?.data?.cars
      : []
  const isUserCars = slides.length > 0

  if (!isUserAuthorized) {
    return <SignIn />
  }

  return (
    <Component>
      <Dropdown
        label={`${profile.data?.name ?? ''} ${profile.data?.lastName ?? ''}`}
      >
        <Content>
          {isUserCars ? (
            <Slider slides={slides} />
          ) : preview.data?.availability?.cars ? (
            <NoCars infoType='archived' />
          ) : (
            <NoCars infoType='noCars' />
          )}

          <Menu
            items={[
              {
                label: 'Личные данные',
                url: '/personal',
              },
            ]}
          />

          <Menu
            items={[
              preview.data?.availability?.cars
                ? {
                    label: 'Мои автомобили',
                    url: '/personal/cars',
                  }
                : undefined,
              preview.data?.availability?.purchasesCars ||
              preview.data?.availability?.purchasesOther ||
              preview.data?.availability?.purchasesInsurances ||
              preview.data?.availability?.purchasesServices
                ? {
                    label: 'Мои покупки',
                    url: '/personal/purchases',
                  }
                : undefined,
              preview.data?.availability?.requests
                ? {
                    label: 'Мои заявки',
                    url: '/personal/requests',
                  }
                : undefined,
              preview.data?.availability?.bonuses
                ? {
                    label: 'Мои бонусы',
                    url: '/personal/bonuses',
                  }
                : undefined,
            ].filter(isNotNullish)}
          />

          <Logout />
          <Actions />
          <Promo />
        </Content>
      </Dropdown>
    </Component>
  )
}

export default User

const Component = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-self: stretch;
  margin-left: 10px;
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`
