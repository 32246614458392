import React from 'react'
import styled from '@emotion/styled'

interface Props {
  title?: string
  children?: React.ReactNode
}

function CabinetFormTemplate({ title, children }: Props) {
  return (
    <Template>
      {title && <Title>{title}</Title>}
      <Content>{children}</Content>
    </Template>
  )
}

const Template = styled.div``

const Title = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${props => props.theme.blue100};
  padding: 0;
  margin-bottom: 20px;
`

const Content = styled.div`
  margin: -15px -16px;
`

export default CabinetFormTemplate
