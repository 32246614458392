import React from 'react';
import styled from '@emotion/styled'
import { css } from '@emotion/react'

interface Props {
  bonusesValue: number;
}

function Convert({ bonusesValue }: Props) {
  return (
    <Block>
      <Balance>
        <Value isLarge={true}>{bonusesValue ?? 0}</Value>
        <Label>бонусов</Label>
      </Balance>
      <Price>
        <PriceCol>
          <Value>1</Value>
          <Label>бонус</Label>
        </PriceCol>
        <PriceCol equality={true}>=</PriceCol>
        <PriceCol>
          <Value>1</Value>
          <Label>byn</Label>
        </PriceCol>
      </Price>
    </Block>
  );
}

const Block = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

const Balance = styled.div``;

const Price = styled.div`
  display: flex;
`;

const PriceCol = styled.div<{ equality?: boolean }>`
  ${(props) =>
    props.equality &&
    css`
      margin: 0 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    `}
`;

const Value = styled.div<{ isLarge?: boolean }>`
  font-size: 24px;
  line-height: 26px;

  ${(props) =>
    props.isLarge &&
    css`
      font-weight: 700;
    `}
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
`;

export default Convert;
