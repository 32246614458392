import { css, Theme } from '@emotion/react'
import styled from '@emotion/styled'

import { CssSnippet } from '@/utils/mixin'

export type ReservedButtonVariant = 'contained' | 'outlined'

export type ButtonVariant = ReservedButtonVariant | CssSnippet

const variantCssMap = ({
  theme,
}: {
  theme: Theme
}): Record<ReservedButtonVariant, CssSnippet> => {
  return {
    contained: css`
      background-color: ${theme.main};
      color: ${theme.white};
      box-shadow: none;

      &:hover {
        background-color: ${theme.main100};
      }
    `,
    outlined: css`
      background-color: ${theme.white};
      color: ${theme.black100};
      box-shadow: 0 0 0 1px ${theme.main} inset;

      &:hover {
        color: ${theme.blue100};
      }
    `,
  }
}

export const StyledCabinetButton = styled.button<{
  variant?: ButtonVariant
  disabled?: boolean
}>`
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  padding: 11px 26px;
  text-decoration: none;
  transition: 0.15s;
  border-radius: 20px;
  height: 40px;

  ${props =>
    typeof props.variant === 'string'
      ? variantCssMap({ theme: props.theme })[props.variant]
      : props.variant};

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`
//

// ---------------------------------------outline
