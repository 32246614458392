import React from 'react'
import styled from '@emotion/styled'

import { ServiceMenuProps } from './types'

import {
  headerServices,
  HeaderVariables,
} from '@/components/Layout/components/Header/components/Menu/constants'
import MenuColumn from '@/components/Layout/components/Header/components/MenuColumn'
import MenuItem from '@/components/Layout/components/Header/components/Menu/MenuMobile/components/MenuItem'
import InfoLink from '@/components/Layout/components/Header/components/InfoLink'

function ServiceMenu({ menuItem }: ServiceMenuProps) {
  const serviceOnline = menuItem.children.find(item =>
    item.label.includes(HeaderVariables.ServiceOnline),
  )

  return (
    <MenuItem
      label={menuItem.label.replace(HeaderVariables.Service, '').trim()}
      link={menuItem.link ?? ''}
      isNewTab={menuItem.isNewTab}
    >
      {headerServices.map(({ items }, index) => (
        <MenuColumnWrapper key={index}>
          <MenuColumn
            menu={
              items && items.length !== 0
                ? items.map(({ image, name, link, isNewTab }) => ({
                    image,
                    title: name ?? '',
                    link: link ?? '',
                    isNewTab,
                  }))
                : []
            }
          />
        </MenuColumnWrapper>
      ))}

      {serviceOnline && (
        <MenuColumnWrapper>
          <InfoLink
            label={serviceOnline.label
              .replace(HeaderVariables.ServiceOnline, '')
              .trim()}
            link={serviceOnline.link ?? ''}
            info={''}
            isNewTab={serviceOnline.isNewTab}
          />
        </MenuColumnWrapper>
      )}
    </MenuItem>
  )
}

export default ServiceMenu

const MenuColumnWrapper = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid ${props => props.theme.gray200};
  }
`
