import React from 'react'

import Router from './router/Router'
import { ThemeProvider } from './theme'

import ProfileProvider from '@/providers/Profile/ProfileProvider'
import AuthProvider from '@/providers/Auth/AuthProvider'

function App() {
  return (
    <ThemeProvider>
      <AuthProvider>
        <ProfileProvider>
          <Router />
        </ProfileProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

export default App
