import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'

import Logo from './components/Logo'

import LocationDesktop from '@/components/Layout/components/Header/components/Location/LocationDesktop'
import LocationMobile from '@/components/Layout/components/Header/components/Location/LocationMobile'
import { media } from '@/utils/mixin'
import MenuDesktop from '@/components/Layout/components/Header/components/Menu/MenuDesktop'
import DesktopPhone from '@/components/Layout/components/Header/components/Phone/DesktopPhone'
import MobilePhone from '@/components/Layout/components/Header/components/Phone/MobilePhone'
import MenuMobile from '@/components/Layout/components/Header/components/Menu/MenuMobile'
import {
  MobileUser,
  User,
} from '@/components/Layout/components/Header/components/User'

interface Props {
  className?: string
}

function Header({ className }: Props) {
  return (
    <Component className={className}>
      <Container>
        <Logo />
        <MenuDesktop />
        <LocationDesktop />
        <DesktopPhone />
        <User />
      </Container>
      <MobileContainer>
        <Logo />
        <Right>
          <LocationMobile />
          <MobileUser />
          <MobilePhone />
          <MenuMobile />
        </Right>
      </MobileContainer>
    </Component>
  )
}

export default Header

const Component = styled.div`
  z-index: 9998;
  position: relative;
  height: 80px;
  border-bottom: 1px solid ${props => props.theme.blue_1};
  background: ${props => props.theme.blue};

  ${media.tablet(css`
    position: sticky;
    top: 0;
  `)}

  ${media.mobile(css`
    height: 60px;
  `)}
`

const Container = styled.div`
  padding: 0 40px;
  height: 80px;
  display: flex;
  align-items: center;

  ${media.tablet(css`
    display: none;
  `)}
`

const Right = styled.div`
  display: flex;
  align-items: center;
`

const MobileContainer = styled.div`
  padding: 0 20px;
  height: 80px;
  display: none;
  align-items: center;

  ${media.tablet(css`
    display: flex;
    justify-content: space-between;
  `)}

  ${media.mobile(css`
    height: 60px;
  `)}
`
