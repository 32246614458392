import React from 'react'
import { Formik, FormikErrors, FormikHelpers } from 'formik'
import { toast } from 'react-toastify'

import DeletePhoneForm, { DeletePhoneFormValues } from './DeletePhoneForm'

import { deletePhone } from '@/services/users/users-service'
import { convertRequestErrorToMap } from '@/utils/common'
import { useProfile } from '@/providers/Profile'

interface Props {
  closeModal: () => void
  contact?: string
}

function DeletePhoneFormContainer({ closeModal, contact }: Props) {
  const { getProfileDataAction } = useProfile()
  const [isLoading, setLoading] = React.useState<boolean>(false)

  async function handleSubmit(
    values: DeletePhoneFormValues,
    formikHelpers: FormikHelpers<DeletePhoneFormValues>,
  ) {
    setLoading(true)

    values = { ...values, phone: contact ?? '' }

    await deletePhone(values)
      .then(() => {
        getProfileDataAction().finally(() => {
          setLoading(false)
          closeModal()
        })
      })
      .catch((error: any) => {
        const errorMap = convertRequestErrorToMap(
          error,
        ) as FormikErrors<DeletePhoneFormValues>
        formikHelpers.setErrors(errorMap)
        setLoading(false)
        toast.error(errorMap.phone)
      })
  }

  return (
    <Formik<DeletePhoneFormValues>
      onSubmit={handleSubmit}
      initialValues={{
        phone: '',
      }}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {formProps => (
        <DeletePhoneForm
          {...formProps}
          isLoading={isLoading}
          phone={contact ?? ''}
        />
      )}
    </Formik>
  )
}

export default DeletePhoneFormContainer
