import cookie from '../utils/cookie'

import { ThemeColorsType, ThemeTypes } from './types'
import { mainDarkColors, mainLightColors } from './colors'

import { Nullable } from '@/typings/common'

export const getInitialTheme = (): ThemeTypes => {
  const cookieTheme = cookie.get('theme') as Nullable<ThemeTypes>

  if (cookieTheme) {
    return cookieTheme
  }
  return 'mainLight'
}

export const getNextTheme = (currentTheme: ThemeTypes): ThemeTypes => {
  return currentTheme === 'mainLight' ? 'mainDark' : 'mainLight'
}

export const isThemeVisible = (): boolean => {
  return (
    process.env.NEXT_PUBLIC_SITE_DARK_THEME === '1' ||
    process.env.NEXT_PUBLIC_SITE_DARK_THEME === 'true'
  )
}

export const getColorsByTheme = (theme: ThemeTypes): ThemeColorsType => {
  switch (theme) {
    case 'mainLight':
      return mainLightColors
    case 'mainDark':
      return mainDarkColors
    default:
      return mainLightColors
  }
}
